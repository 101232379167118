import { CompanyLite, User } from '../types.ts';
import { useEffect, useState } from 'react';
import determineActiveCompany, {
  NoAccessToCompanyError,
} from './determineActiveCompany.js';
import useLocalStorageState from '../services/useLocalStorageState.js';
import { assignUrlWrapper } from './assignUrlWrapper.js';
import { Result } from '../services/useFetch/useFetch.js';
import useApi from '../api/useApi.js';
import { Session } from '../api/ApiSession.js';
import * as logger from '../logging/logger.ts';
import {
  ErrorResult,
  LoadingResult,
  SuccessResult,
  isSuccessResult,
} from '../result/Result.js';
import WithResultLoading from '../result/WithResultLoading.js';
import FullPageLoading from '../pages/FullPageLoading.js';
import { activeCompanyAtom } from './activeCompanyAtom.tsx';
import { useSetAtom, useAtom } from 'jotai';
import { userAtom } from './userAtom.tsx';
export type UserAndCompany = {
  user: User;
  activeCompany: CompanyLite;
  updateActiveCompany: (company: CompanyLite) => void;
  setActiveCompany: (company: { id: string }) => void;
  updateActiveUser: (user: Pick<User, 'firstName' | 'lastName'>) => void;
  userCompanies: CompanyLite[];
};

type FetchUserAndCompanyProps = {
  apiSession: Session;
  renderWithCompanyAccess: (userAndCompany: UserAndCompany) => JSX.Element;
  renderWithNoCompanyAccess: (user: User) => JSX.Element;
};

const FetchUserAndCompany = ({
  apiSession,
  renderWithCompanyAccess,
  renderWithNoCompanyAccess,
}: FetchUserAndCompanyProps) => {
  const [result, setResult] =
    useState<Result<{ user: User; companies: CompanyLite[] }>>(LoadingResult());
  const { getUser, getUserCompanies, updateCompany } = useApi();
  const [activeCompany, setActiveCompany] = useAtom(activeCompanyAtom);
  const setUser = useSetAtom(userAtom);
  const [localCompanyId, setLocalCompanyId] = useLocalStorageState('companyId');
  const [doesNotHaveAccessToCompanyInUrl, setDoesNotHaveAccessToCompanyInUrl] =
    useState(false);

  const updateUser = ({
    firstName,
    lastName,
  }: {
    firstName: string;
    lastName: string;
  }) => {
    if (!isSuccessResult(result)) {
      return;
    }
    const updatedUser = {
      ...result.data.user,
      firstName,
      lastName,
    };
    setResult(
      SuccessResult({
        user: updatedUser,
        companies: result.data.companies,
      }),
    );
  };
  useEffect(() => {
    Promise.all([getUser(), getUserCompanies()])
      .then(([user, companies]: [User, CompanyLite[]]) => {
        setResult(
          SuccessResult({
            user,
            companies,
          }),
        );

        if (companies.length > 0) {
          try {
            const activeCompany = determineActiveCompany(
              companies,
              window.location.pathname,
              localCompanyId,
            );

            setActiveCompany(activeCompany);
            setUser(user);
          } catch (e) {
            setActiveCompany(undefined);
            setUser(undefined);
            if (e instanceof NoAccessToCompanyError) {
              setDoesNotHaveAccessToCompanyInUrl(true);
            } else {
              throw e;
            }
          }
        }
      })
      .catch(() => {
        setResult(ErrorResult(undefined));
      });

    // We have explicitly chosen not to re-fire this useEffect's code
    // when the localCompanyId changes.
    //
    // The reason for this is that when the company switcher is used to change
    // companies, this triggers both an update of the localCompanyId and a
    // hard URL change. If a change to localCompanyId causes this code to re-fire,
    // then it will immediately initiate a refetch of the user and company data.
    // However, the hard URL change that is also initiated by the company switch
    // will result in the cancellation of these fetch commands. This generates
    // an error that will be reported in New Relic even though the user's
    // experience is unaffected (for some reason Safari is the only one that actually
    // _does_ report it to New Relic, wtf...)
    //
    // TL;DR -- we probably need to refactor this whole FetchUserAndCompany thing
    //          so that the relationship between local storage and the app's interaction
    //          with the browser's URL doesn't clash so much.
    //
    // local company id can also change if it changes in localStorage, and we dont want to
    // update active company, it'd probably cause bugs
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiSession, getUser, getUserCompanies]);

  useEffect(() => {
    if (result.type === 'success') {
      logger.setUserId(result.data.user.id);
    } else {
      logger.setUserId(null);
    }
  }, [result]);

  useEffect(() => {
    logger.setCompanyId(activeCompany ? activeCompany.id : null);
  }, [activeCompany]);

  return (
    <WithResultLoading
      result={result}
      duration={200}
      renderLoading={() => <FullPageLoading />}
    >
      {(data) => {
        const { user, companies } = data;
        if (doesNotHaveAccessToCompanyInUrl || !activeCompany) {
          return renderWithNoCompanyAccess(user);
        }

        return renderWithCompanyAccess({
          user,
          activeCompany,
          updateActiveCompany(updatedActiveCompany) {
            setActiveCompany(updatedActiveCompany);
            updateCompany(updatedActiveCompany);
          },
          setActiveCompany(newActiveCompany) {
            setLocalCompanyId(newActiveCompany.id);
            hardNavigateToHome();
          },
          userCompanies: companies,
          updateActiveUser: updateUser,
        });
      }}
    </WithResultLoading>
  );
};

export function hardNavigateToHome() {
  assignUrlWrapper(window.location.origin);
}
export default FetchUserAndCompany;

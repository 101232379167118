import { ChartBlock } from 'editor-content/ChartBlock.ts';
import { useAtom } from 'jotai';
import { useErrorModal } from '../../editor/errorModal/ErrorModal.tsx';
import { AiContext } from '../../pages/zeck/editor/AiChartFlow/createAiContext.ts';
import Toolbar, { ToolbarButton, ToolbarGroup } from './Toolbar.tsx';

type ChartFormattingMenuProps = {
  block: ChartBlock;
  aiContext: AiContext;
};

const ChartFormattingMenu: React.FC<ChartFormattingMenuProps> = ({
  block,
  aiContext,
}) => {
  const { error } = useErrorModal();

  const [chartEditingChart, setChartEditingChart] = useAtom(
    aiContext.getChartMakingFamily(block),
  );

  const showChartButton = chartEditingChart;

  if (error || showChartButton) {
    return null;
  }

  return (
    <>
      <Toolbar>
        <ToolbarGroup>
          <ToolbarButton
            iconName="aiPencil"
            aria-label="edit a chart with ai"
            onClick={() => {
              setChartEditingChart(true);
            }}
          >
            Edit with AI
          </ToolbarButton>
        </ToolbarGroup>
      </Toolbar>
    </>
  );
};

export default ChartFormattingMenu;

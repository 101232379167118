import { TableBlock, TableBlockCell } from 'editor-content/TableBlock.ts';

export const getTableCellValue = (cell: TableBlockCell): string => {
  if (typeof cell === 'string') {
    return cell;
  }

  return cell.content.map((textNode) => textNode.text).join('') ?? '';
};

const isChartWorthy = (block: TableBlock): boolean => {
  const { data } = block;

  const firstDataRow = data.rows[1];
  if (!firstDataRow) {
    return false;
  }

  const firstDataRowValues = firstDataRow.cells.map(getTableCellValue);

  return firstDataRowValues.some(
    (value) => !isNaN(Number(value.replace(/[$,%]/g, ''))),
  );
};

export default isChartWorthy;

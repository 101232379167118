import { useMemo } from 'react';
import { useTableRefs } from './useTableRefs.js';
import {
  measureTableBorderOffsets,
  TableMeasurementsRowColumn,
} from './TableMeasurements.js';

export const useTableCloneSync = () => {
  const {
    setScrollContainerEl,
    setTableEl,
    getTableEl,
    setRowElAt,
    getRowElAt,
    setColumnElAt,
    getColumnElAt,
    getScrollContainerEl,
  } = useTableRefs();

  return useMemo(
    () => ({
      setCloneScrollContainerEl: setScrollContainerEl,
      setTableEl: setTableEl,
      setClonedColumnElAt: setColumnElAt,
      setClonedRowElAt: setRowElAt,
      measureBorderOffsets: () =>
        measureTableBorderOffsets({ getTableEl, getRowElAt, getColumnElAt }),
      setTableDimensions: (dimensions: TableMeasurementsRowColumn) => {
        dimensions.columnWidths.forEach((width, columnIndex) => {
          const clonedColumn = getColumnElAt(columnIndex);
          if (!clonedColumn) return;

          clonedColumn.style.width = `${width}px`;
        });

        dimensions.rowHeights.forEach((height, rowIndex) => {
          const clonedRow = getRowElAt(rowIndex);
          if (!clonedRow) return;

          clonedRow.style.height = `${height}px`;
        });
      },
      syncScrollTop: (scrollTop: number): void => {
        const scrollContainer = getScrollContainerEl();
        if (!scrollContainer) return;

        scrollContainer.scrollTop = scrollTop;
      },
      syncScrollLeft: (scrollLeft: number): void => {
        const scrollContainer = getScrollContainerEl();
        if (!scrollContainer) return;

        scrollContainer.scrollLeft = scrollLeft;
      },
    }),
    [
      getColumnElAt,
      getRowElAt,
      getScrollContainerEl,
      getTableEl,
      setColumnElAt,
      setRowElAt,
      setScrollContainerEl,
      setTableEl,
    ],
  );
};

export type TableClone = ReturnType<typeof useTableCloneSync>;

import { Comment, CommentReply } from 'api-client/types.js';
import { CommentContent } from 'editor-content/CommentContent.js';
import { Atom, atom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useMemo, useState } from 'react';
import useApi from '../../../../../../api/useApi.js';
import {
  MaybePendingUser,
  PublishedSection,
  PublishedZeck,
} from '../../../../../../types.ts';
import { UserAndCompany } from '../../../../../../userAndCompany/FetchUserAndCompany.js';
import createCommentsServerStateAtoms from './createCommentsServerStateAtom.js';
import filterComments from './filterComments.js';
import {
  BlockCommentContext,
  getBlockCommentContexts,
} from './getBlockCommentContexts.ts';
import {
  CommentSelectionState,
  getEffectiveCommentContent,
} from './getEffectiveCommentContent.ts';
import isFilteredCommentsExisty from './isFilteredCommentsExisty.js';
import { commentSortOrder } from './sortComments.ts';
import useActionsForComments from './useActionsForComments.js';
import useCommentsUIState, { FilterState } from './useCommentsUIState.js';

export type CommentsStateForSection = {
  activeCommentCount: number;
  filteredCommentsWithActions: CommentWithActions[];
  findComment: (commentId: string) => Comment | undefined;
  newComment: {
    canAddNewComment: boolean;
    openNewComment: () => void;
    cancelNewComment: () => void;
    isNewCommentFormOpen: boolean;
  };
  refs: {
    setCommentElementRef: (
      commentId: string,
    ) => (el: HTMLElement | null) => void;
    setCommentSectionRef: React.RefCallback<HTMLElement>;
    setSectionContentRef: React.RefCallback<HTMLElement>;
  };
  blocks: {
    forBlock: (blockId: string) => CommentWithActions[];
    blockCommentContexts: Record<string, BlockCommentContext>;
    getCommentsCountForBlock: (blockId: string) => number;
  };
  selectedComment: {
    getSelectedCommentThreadId: () => string | null;
    unselectComment: () => void;
    selectCommentThread: (
      commentId: string,
      scrollToSelection?: boolean,
    ) => void;
  };
  openComment: (commentId: string) => void;
  openSectionComments: () => void;
  postComment: (
    content: CommentContent,
    asDirectMessage: boolean,
    selection?: Comment['selection'],
  ) => Promise<Comment>;
  scrollSectionIntoViewIfNeeded: () => void;
  sidebarOpen: boolean;
  isCommentToCelebrate: (commentId: string) => boolean;

  // these are kinda jank but was useful in
  // a globalstateless world
  section: {
    id: string;
    title: string;
  };
  userName: string;
  viewers: MaybePendingUser[];
};

export type CommentsState = {
  sidebarOpen: boolean;
  totalCommentCountAtom: Atom<number>;
  filteredCommentsExistAtom: Atom<boolean>;
  isCommentsLoaded: boolean;
  filterState: FilterState;
  setCommentsPanelRef: React.RefCallback<HTMLElement>;
  setZeckScrollContainerRef: React.RefCallback<HTMLElement>;
  setFilter: (filterState: FilterState) => void;
  openComments: () => void;
  closeComments: () => void;
  forSection: (publishedSection: PublishedSection) => CommentsStateForSection;
} | null;

function useLoadComments(publishedZeck: PublishedZeck) {
  const api = useApi();
  const { getZeckViewers } = api;

  const [viewers, setViewers] = useState<MaybePendingUser[]>([]);
  const [isCommentsLoaded, setIsCommentsLoaded] = useState(false);
  const commentsAtoms = useMemo(
    () => createCommentsServerStateAtoms(api, publishedZeck),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [api, publishedZeck.id],
  );

  const loadComments = useSetAtom(commentsAtoms.loadCommentsAtom);

  const zeckId = publishedZeck.zeckId;
  const zeckDisabledComments = publishedZeck.settings.disableComments;

  useEffect(() => {
    if (zeckDisabledComments) {
      return;
    }

    loadComments().then(() => setIsCommentsLoaded(true));
    getZeckViewers(zeckId).then(setViewers);
  }, [
    loadComments,
    getZeckViewers,
    zeckId,
    zeckDisabledComments,
    publishedZeck.sections,
  ]);

  return {
    isCommentsLoaded,
    viewers,
    commentsAtoms,
  };
}

export function useCommentsForZeck(
  userAndCompany: UserAndCompany,
  publishedZeck: PublishedZeck,
): CommentsState | null {
  const { isCommentsLoaded, viewers, commentsAtoms } =
    useLoadComments(publishedZeck);

  const [selectedCommentThreadId, setSelectedCommentThreadId] = useState<
    string | null
  >(null);

  const {
    setCommentSectionRef,
    setSectionContentRef,
    setCommentElementRef,
    setZeckScrollContainerRef,
    openNewCommentFor,
    openCommentsToSection,
    scrollToComment,
    openComments,
    closeComments,
    isSidebarOpen,
    filterState,
    setFilter,
    setCommentsPanelRef,
    forSection: uiStateForSection,
  } = useCommentsUIState();

  const {
    getActionsForComment: _getActionsForComment,
    getActionsForCommentReply: _getActionsForCommentReply,
  } = useActionsForComments(userAndCompany, commentsAtoms);

  const addComment = useSetAtom(commentsAtoms.addComment);
  const commentsAndDMsBySection = useAtomValue(
    commentsAtoms.commentsBySectionAtom,
  );

  const totalCommentCountAtom = useMemo(
    () =>
      atom((get) =>
        Object.values(get(commentsAtoms.commentsBySectionAtom)).reduce(
          (count, comments) =>
            count +
            comments.length +
            comments.reduce(
              (count, comment) => count + comment.replies.length,
              0,
            ),
          0,
        ),
      ),
    [commentsAtoms.commentsBySectionAtom],
  );

  const commentToCelebrate = useAtomValue(commentsAtoms.commentToCelebrateAtom);
  const doFilteredCommentsExistAtom = useMemo(
    () =>
      atom((get) =>
        isFilteredCommentsExisty(
          filterState,
          get(commentsAtoms.commentsBySectionAtom),
        ),
      ),
    [filterState, commentsAtoms.commentsBySectionAtom],
  );

  if (publishedZeck.settings.disableComments) {
    return null;
  }

  return {
    isCommentsLoaded,
    filterState,
    totalCommentCountAtom: totalCommentCountAtom,
    filteredCommentsExistAtom: doFilteredCommentsExistAtom,
    setFilter,
    sidebarOpen: isSidebarOpen || !!selectedCommentThreadId,
    setZeckScrollContainerRef,
    setCommentsPanelRef,
    openComments,
    closeComments: () => {
      closeComments();
      setSelectedCommentThreadId(null);
    },
    forSection(publishedSection: PublishedSection): CommentsStateForSection {
      const sectionId = publishedSection.sectionId;

      const {
        isNewCommentFormOpen,
        openSectionComments,
        closeNewComment,
        scrollSectionIntoViewIfNeeded,
        canAddNewComment,
        scrollToCommentInZeck,
      } = uiStateForSection(sectionId);

      const commentsForSection = commentsAndDMsBySection[sectionId] || [];

      const userName =
        userAndCompany.user.firstName + ' ' + userAndCompany.user.lastName;

      const { activeComments, resolvedComments, starredComments } =
        filterComments(commentsForSection);

      const filteredComments = (() => {
        switch (filterState) {
          case 'resolved':
            return resolvedComments;
          case 'starred':
            return starredComments;
          case null:
            return activeComments;
        }
      })();

      const findComment = (commentId: string): Comment | undefined => {
        return commentsForSection.find((c) => c.id === commentId);
      };

      const filteredCommentsWithActions = filteredComments
        .map((comment): CommentWithActions => {
          const effectiveSelection = getEffectiveCommentContent(
            comment,
            publishedSection,
          );

          return {
            ...comment,
            replies: comment.replies.map((commentReply) => ({
              ...commentReply,
              actions: _getActionsForCommentReply(commentReply),
            })),
            actions: _getActionsForComment(comment),
            ...effectiveSelection,
          };
        })
        .sort(commentSortOrder);

      const forBlock = (blockId: string) => {
        return filteredCommentsWithActions.filter(
          (c) => c.selection?.block.id === blockId,
        );
      };

      const blockCommentContexts = getBlockCommentContexts(
        filteredCommentsWithActions,
        publishedSection,
      );

      const postComment = async (
        content: CommentContent,
        asDirectMessage = false,
        selection?: Comment['selection'],
      ) => {
        const comment = await addComment(
          sectionId,
          content,
          asDirectMessage,
          selection,
        );

        closeNewComment();

        return comment;
      };

      const activeCommentCount =
        activeComments.length +
        activeComments.reduce((count, c) => count + c.replies.length, 0);

      const getCommentsCountForBlock = (blockId: string) => {
        const commentsForBlock = filteredCommentsWithActions.filter(
          (c) => c.selection?.block.id === blockId,
        );

        const commentRepliesCount =
          commentsForBlock?.reduce((acc, comment) => {
            return acc + comment.replies.length;
          }, 0) ?? 0;

        const commentCount =
          (commentsForBlock?.length ?? 0) + commentRepliesCount;
        return commentCount;
      };

      const isCommentToCelebrate = (commentId: string): boolean => {
        return commentToCelebrate?.id === commentId;
      };

      return {
        findComment,
        newComment: {
          canAddNewComment,
          cancelNewComment: closeNewComment,
          isNewCommentFormOpen,
          openNewComment: () => openNewCommentFor(sectionId),
        },
        refs: {
          setCommentElementRef,
          setCommentSectionRef: setCommentSectionRef(sectionId),
          setSectionContentRef: setSectionContentRef(sectionId),
        },
        section: {
          id: sectionId,
          title: publishedSection.title,
        },
        blocks: {
          forBlock,
          getCommentsCountForBlock,
          blockCommentContexts,
        },
        selectedComment: {
          getSelectedCommentThreadId: () => selectedCommentThreadId,
          unselectComment: () => setSelectedCommentThreadId(null),

          selectCommentThread: (commentId, scrollToSelection = false) => {
            setSelectedCommentThreadId(commentId);

            if (scrollToSelection) {
              scrollToCommentInZeck(commentId);
            }
          },
        },
        activeCommentCount,
        filteredCommentsWithActions,
        openComment: (commentId) => {
          openCommentsToSection(sectionId, false);
          setSelectedCommentThreadId(commentId);

          setTimeout(() => {
            scrollToComment(commentId);
          }, 400);
        },
        openSectionComments,
        postComment,
        scrollSectionIntoViewIfNeeded,
        sidebarOpen: isSidebarOpen,
        userName,
        viewers,
        isCommentToCelebrate,
      };
    },
  };
}

export type CommentReplyWithActions = CommentReply & {
  actions: {
    edit?: (content: CommentContent) => Promise<void>;
    delete?: () => Promise<void>;
  };
};

export type CommentWithActions = Omit<Comment, 'replies'> & {
  replies: CommentReplyWithActions[];
  actions: {
    toggleStar: (() => void) | undefined;
    edit?: (content: CommentContent) => Promise<void>;
    delete?: () => Promise<void>;
    reply: (content: CommentContent) => Promise<void>;
    resolve?: () => void;
    unresolve?: () => void;
  };
} & CommentSelectionState;

import type { Metadata } from './providers/base.js';
import { match } from './providers/index.js';

/**
 * A common interface for getting information about hosted videos.
 *
 * Currently, only YouTube and Vimeo are supported.
 */
export class VideoClient {
  /**
   * Get normalized metadata about a video, given its URL.
   *
   * @param url - The url to a video
   * @returns Normalized video metadata
   */
  async getMetadata(url: string) {
    const video = match(url);
    return await video.getMetadata();
  }
}

export class TestVideoClient extends VideoClient {
  #data: Record<string, Metadata>;

  constructor(data: Record<string, Metadata>) {
    super();
    this.#data = data;
  }

  async getMetadata(url: string) {
    const metadata = this.#data[url];
    if (metadata) {
      return metadata;
    } else {
      throw new Error('Video not found');
    }
  }
}

export type { Metadata } from './providers/base.js';

import VoteResult from './VoteResult.tsx';
import {
  ManagementPageShell,
  ManagementPageTab,
  ManagementPageTabs,
  ManagementPageTitle,
} from '../ManagementPage.tsx';
import useApi from '../../api/useApi.ts';
import useFetchUpdate from '../../services/useFetch/useFetchUpdate.ts';
import Vote from '../../types/Vote.ts';
import WithResultLoading from '../../result/WithResultLoading.tsx';
import LoaderSpinnerOverlay from '../LoaderSpinnerOverlay.tsx';
import { useDocumentTitle } from '../../junkDrawer/useDocumentTitleFromResult.ts';
import { UserAndCompany } from '../../userAndCompany/FetchUserAndCompany.tsx';
import usePageTracking from '../../services/usePageTracking.ts';

type VotingPageProps = {
  companyId: string;
  userAndCompany: UserAndCompany;
};

const VotingPage: React.FunctionComponent<VotingPageProps> = ({
  companyId,
  userAndCompany,
}) => {
  useDocumentTitle('Votes');
  usePageTracking('voting_page', userAndCompany);

  const { getCompanyVotes } = useApi();
  const { result: votesResult, update: setVotesResult } = useFetchUpdate(
    () => getCompanyVotes(companyId),
    [companyId],
  );

  const tabs: ManagementPageTab[] = [
    { name: 'Meeting Minutes', link: `/company/${companyId}/minutes` },
    { name: 'Votes', active: true },
  ];

  return (
    <WithResultLoading
      result={votesResult}
      duration={400}
      renderError={() => <div>Error loading Votes.</div>}
      renderLoading={() => <LoaderSpinnerOverlay data-testid="votes-spinner" />}
    >
      {(votes) => {
        const onVoteUpdated = (updatedVote: Vote) => {
          setVotesResult(
            votes.map((v) => {
              if (v.id === updatedVote.id) {
                return updatedVote;
              }

              return v;
            }),
          );
        };

        const onVoteDeleted = (deletedVoteId: string) => {
          setVotesResult(
            votes.filter((v) => {
              return v.id !== deletedVoteId;
            }),
          );
        };

        return (
          <ManagementPageShell>
            <ManagementPageTitle title="Minutes Book" />
            <ManagementPageTabs tabs={tabs} />
            {votes.map(
              ({ id, sectionId, title, details, approved, createdAt }) => (
                <VoteResult
                  key={id}
                  {...{
                    voteId: id,
                    sectionId,
                    companyId,
                    title,
                    details,
                    approved,
                    votedAt: createdAt,
                    onVoteUpdated: onVoteUpdated,
                    onVoteDeleted: onVoteDeleted,
                  }}
                />
              ),
            )}
          </ManagementPageShell>
        );
      }}
    </WithResultLoading>
  );
};

export default VotingPage;

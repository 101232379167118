import { compressTableBlock, TableBlock } from 'editor-content/TableBlock.ts';
import { IntegrationListItemData } from '../../../../api/endpoints/createIntegrationApi.ts';
import { User } from '../../../../types.ts';
import MicrosoftTableSyncFlow from './MicrosoftTableSyncFlow.tsx';
import GoogleTableSyncFlow from './GoogleTableSyncFlow.tsx';
import { useState } from 'react';
import { BlockInteractiveRenderState } from './InteractiveState.ts';
import HoverNextToPoint from '../../../../domHelpers/hoverNextTo/HoverNextToPoint.tsx';
import TableFormattingMenu from '../../../../design-system/organisms/TableFormattingMenu.tsx';
import useToast from '../../../../design-system/molecules/toast/useToast.ts';
import Toast from '../../../../design-system/molecules/toast/Toast.tsx';
import { AiContext } from '../AiChartFlow/createAiContext.ts';

type TableFormattingExperienceProps = {
  block: TableBlock;
  getEl: () => HTMLElement | undefined;
  onReplaceTable: (block: TableBlock) => void;
  user: User;
  integrationData: IntegrationListItemData;
  aiContext: AiContext;
};

const TableFormattingExperience: React.FC<TableFormattingExperienceProps> = ({
  block,
  user,
  integrationData,
  getEl,
  onReplaceTable,
  aiContext,
}) => {
  const [interactiveState, setInteractiveState] =
    useState<BlockInteractiveRenderState>({ type: 'formatting' });
  const { showToast, ...toast } = useToast();

  const onSyncComplete = () => {
    setInteractiveState({ type: 'formatting' });
  };

  const { integrations } = integrationData;

  const integration = integrations.find(
    (i) => i.id === block.integrationId && i.userId === user.id,
  );

  const integrationFlowProps = {
    block,
    integrationData,
    getEl,
    onReplaceTable,
    interactiveState,
    onSyncComplete,
  };

  return (
    <>
      {interactiveState.type === 'formatting' && (
        <HoverNextToPoint
          getPoint={(popoverEl) => {
            const targetEl = getEl();
            if (!targetEl) return [0, 0];

            const targetRect = targetEl.getBoundingClientRect();
            const popoverRect = popoverEl.getBoundingClientRect();
            return [
              targetRect.x + targetRect.width / 2 - popoverRect.width / 2,
              targetRect.y - popoverRect.height - 16,
            ];
          }}
          usePortal
          viewportPolicy="none"
        >
          <TableFormattingMenu
            showSyncButton={!!integration}
            onClickSync={() => {
              if (!integration) return;

              setInteractiveState({ type: 'syncing' });
            }}
            onFreeze={(table) => {
              onReplaceTable(compressTableBlock(table)); // TODO: replaceTable is not the thing
            }}
            block={block}
            aiContext={aiContext}
          />
        </HoverNextToPoint>
      )}
      {interactiveState.type === 'syncing' &&
        integration &&
        integration.provider == 'microsoft' && (
          <MicrosoftTableSyncFlow
            {...{
              ...integrationFlowProps,
              showToast,
              integration: integration,
            }}
          />
        )}
      {interactiveState.type === 'syncing' &&
        integration &&
        integration.provider == 'google' && (
          <GoogleTableSyncFlow
            {...{ ...integrationFlowProps, integration: integration }}
          />
        )}
      <Toast {...toast} />
    </>
  );
};

export default TableFormattingExperience;

import 'regenerator-runtime';
import { ValueType, Workbook } from 'exceljs';
import { DocumentMeta } from '../../integrationModalTypes.ts';
import { FileBufferWithModified } from './getArrayBufferOfOneDriveItem.ts';

export const createExcelWorkbookData = async (
  fileWithModified: FileBufferWithModified,
  {
    documentId,
    documentName,
    driveId,
  }: {
    documentId: string;
    documentName: string;
    driveId?: string;
  },
): Promise<{
  workbook: Workbook;
  documentMeta: DocumentMeta;
  valueTypes: typeof ValueType;
}> => {
  // dynamic import since this dependency is huge
  return await import('exceljs').then(async ({ Workbook, ValueType }) => {
    return await _parseArrayBuffer({
      fileWithModified,
      ExcelJSWorkbook: Workbook,
      valueTypes: ValueType,
      documentId,
      documentName,
      driveId,
    });
  });
};

// only exported to test
export const _parseArrayBuffer = async ({
  fileWithModified,
  ExcelJSWorkbook,
  valueTypes,
  documentId,
  documentName,
  driveId,
}: {
  fileWithModified: FileBufferWithModified;
  ExcelJSWorkbook: typeof Workbook;
  valueTypes: typeof ValueType;
  documentId: string;
  documentName: string;
  driveId?: string;
}) => {
  const workbook = new ExcelJSWorkbook();
  const { buffer, lastModified } = fileWithModified;

  // can skip loading most of workbook data to improve perf and reduce memory
  await workbook.xlsx.load(buffer, {
    ignoreNodes: [
      'sheetPr',
      'sheetFormatPr',
      'autoFilter',
      'rowBreaks',
      'hyperlinks',
      'pageMargins',
      'dataValidations',
      'pageSetup',
      'headerFooter',
      'printOptions',
      'picture',
      'drawing',
      'sheetProtection',
      'tableParts',
      'conditionalFormatting',
      'extLst',
    ],
  });

  const sheets = workbook.worksheets.map((sheet, index) => ({
    title: sheet.name,
    sheetId: sheet.id,
    index,
  }));

  const namedRangeStrings: string[] = [];
  workbook.definedNames.forEach((name) => {
    if (namedRangeStrings.indexOf(name) === -1) {
      namedRangeStrings.push(name);
    }
  });

  return {
    workbook,
    documentMeta: {
      documentId,
      documentName,
      sheets,
      namedRanges: namedRangeStrings.map((name) => ({
        name,
      })),
      lastModified,
      driveId,
    },
    valueTypes,
  };
};

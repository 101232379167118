import BaseVideo, { Metadata } from './base.js';

export default class YouTubeVideo extends BaseVideo {
  static match(url: string): boolean {
    const host = new URL(url).host;
    return host.includes('youtube.com') || host.includes('youtu.be');
  }

  #url: string;

  constructor(url: string) {
    super();
    this.#url = url;
  }

  async getMetadata(): Promise<Metadata> {
    const response = await fetch(
      `https://www.youtube.com/oembed?format=json&url=${encodeURIComponent(
        this.#url,
      )}`,
    );
    if (response.status !== 200) {
      throw new Error('Video not found');
    }

    const data = (await response.json()) as {
      author_name: string;
      title: string;
      thumbnail_url: string;
      html: string;
    };

    return {
      url: this.#url,
      thumbnail: data.thumbnail_url.replace('hqdefault', 'maxresdefault'),
      author: data.author_name,
      title: data.title,
      html: data.html,
      iframe: this.getIframeAttributes(data.html),
    };
  }
}

import { useActiveCompany } from '../../../userAndCompany/activeCompanyAtom.tsx';
import { useUser } from '../../../userAndCompany/userAtom.tsx';
import styles from './PdfFrame.module.scss';

type PdfFrameProps = {
  pdfUrl: string;
};

const PdfFrame: React.FC<PdfFrameProps> = ({ pdfUrl }) => {
  const user = useUser();
  const activeCompany = useActiveCompany();

  return (
    <iframe
      src={`/frames/pdfViewer/index.html?pdfUrl=${pdfUrl}&companyId=${activeCompany.id}&userId=${user.id}`}
      title="PDF Viewer"
      className={styles.pdfFrame}
      sandbox="allow-scripts"
    />
  );
};

export default PdfFrame;

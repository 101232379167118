import BaseVideo, { Metadata } from './base.js';

export default class VimeoVideo extends BaseVideo {
  static match(url: string): boolean {
    return new URL(url).host.includes('vimeo.com');
  }

  #url: string;

  constructor(url: string) {
    super();
    this.#url = url;
  }

  async getMetadata(): Promise<Metadata> {
    const response = await fetch(
      `https://vimeo.com/api/oembed.json?url=${encodeURIComponent(
        this.#url,
      )}&width=1920`,
    );
    if (response.status !== 200) {
      throw new Error('Video not found');
    }

    const data = (await response.json()) as {
      author_name: string;
      title: string;
      thumbnail_url: string;
      html: string;
    };

    return {
      url: this.#url,
      thumbnail: data.thumbnail_url,
      author: data.author_name,
      title: data.title,
      html: data.html,
      iframe: this.getIframeAttributes(data.html),
    };
  }
}

import { Vote } from 'editor-content/VoteBlock.ts';
import React, { useEffect, useRef } from 'react';
import useApi from '../../../../api/useApi.js';
import Toast from '../../../../design-system/molecules/toast/Toast.tsx';
import useToast from '../../../../design-system/molecules/toast/useToast.ts';
import { BrandKitResource } from '../../../../design-system/zeck/WithBrandKit.tsx';
import mergeRefs from '../../../../junkDrawer/mergeRefs.js';
import MeetingMinutesSignature from '../../../../meetingMinutes/MeetingMinutesSignature.js';
import MeetingMinutesView from '../../../../meetingMinutes/MeetingMinutesView.js';
import { foldResult, pipe } from '../../../../result/Result.js';
import useAnalyticsEvents from '../../../../services/useAnalyticsEvents.js';
import useFetch from '../../../../services/useFetch/useFetch.js';
import { PublishedSection, PublishedZeck } from '../../../../types.ts';
import { Prevote, PrevoteChoice } from '../../../../types/Prevote.ts';
import { UserAndCompany } from '../../../../userAndCompany/FetchUserAndCompany.tsx';
import { isSignedMinutes } from '../../../meetingMinutes/MeetingMinutes.js';
import { makeMeetingMinutesLinkable } from '../../editor/MeetingMinutesLinkable.js';
import { makeSectionLinkable } from '../../editor/SectionLinkable.ts';
import LinkDrawer from '../../links/LinkDrawer/LinkDrawer.tsx';
import useLinkDrawer from '../../links/LinkDrawer/useLinkDrawer.js';
import LinkTooltips from '../../links/LinkTooltips/LinkTooltips.js';
import useLinkTooltips from '../../links/LinkTooltips/useLinkTooltips.js';
import {
  BlockPrevoteResult,
  BlockPrevoteTally,
  generateZeckVoteCapabilities,
} from '../../voting/VoteCapability.ts';
import PdfFrame from '../PdfFrame.tsx';
import SectionView from '../SectionView.js';
import useScrollToSection from '../useScrollToSection.ts';
import { useCommentsForZeck } from './commentsSidebar/useComments/useComments.ts';
import getPrimarySectionInContainer from './getPrimarySectionInContainer.js';
import PageWithCommentsSidebar from './PageWithCommentsSidebar.tsx';
import publishedSectionPageHandleUrlHash from './publishedSectionPageHandleUrlHash.tsx';
import PublishedZeckView from './PublishedZeckView.tsx';
import PublishPage from './PublishPage.tsx';

type PublishedSectionPageViewProps = {
  brandKitResource: BrandKitResource;
  zeck: PublishedZeck;
  section?: PublishedSection;
  userAndCompany: UserAndCompany;

  takePrevote: ({
    blockId,
    choice,
    sectionId,
  }: {
    blockId: string;
    choice: PrevoteChoice;
    sectionId: string;
  }) => Promise<void>;
  removePrevote: (id: string) => Promise<void>;
  takeVote: (
    vote: Pick<
      Vote,
      'title' | 'details' | 'blockId' | 'approved' | 'voteType' | 'sectionId'
    >,
  ) => Promise<void>;
  prevoteData: {
    results: BlockPrevoteResult[];
    tallies: BlockPrevoteTally[];
    boardDirectorCount: number;
    currentUserPrevotes: Prevote[];
  };
};

const PublishedSectionPageView: React.FunctionComponent<
  PublishedSectionPageViewProps
> = ({
  brandKitResource,
  zeck,
  section,
  userAndCompany,
  takePrevote,
  removePrevote,
  takeVote,
  prevoteData,
}) => {
  const { getZeckPublishedZeckMeetingMinutes } = useApi();
  const { showToast, ...toast } = useToast();
  // comments stuff
  const commentsState = useCommentsForZeck(userAndCompany, zeck);

  useEffect(() => {
    publishedSectionPageHandleUrlHash(commentsState, section, showToast);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentsState?.isCommentsLoaded, section, showToast]);

  // get data
  const meetingMinutesResult = useFetch(
    () => getZeckPublishedZeckMeetingMinutes(zeck.zeckId),
    [zeck.zeckId],
  );

  const meetingMinutes = pipe(
    meetingMinutesResult,
    foldResult({
      success: (meetingMinutes) => meetingMinutes,
      loading: () => [],
      error: () => [],
    }),
  );

  // scrollTo stuff

  // this one also secretly used for setting window height lower down
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const sectionsWithRef = useScrollToSection(zeck, section);

  function scrollToSectionOrZeckCover(sectionId?: string) {
    if (!sectionId) {
      scrollContainerRef.current?.scrollTo({ top: 0 });
      return;
    }

    const maybeSectionWithRef = sectionsWithRef.find(
      ({ data: section }) => section.id === sectionId,
    );
    if (maybeSectionWithRef) {
      const { getEl } = maybeSectionWithRef;
      getEl()?.scrollIntoView();
    }
  }

  useEffect(() => {
    const currentAnchor = window.location.hash;
    if (currentAnchor) {
      window.location.hash = '';
      window.location.hash = currentAnchor;
    }
  }, []);

  // analytics
  const { trackScrollInSection } = useAnalyticsEvents();

  // zeck actions
  const getZeckCapabilitiesForSection = (sectionId: string) =>
    generateZeckVoteCapabilities({
      userIsAdmin: userAndCompany.activeCompany.role === 'admin',
      userCanManageMinutes:
        userAndCompany.activeCompany.permissions.canManageMinutes,
      userIsBoardDirector: userAndCompany.activeCompany.boardDirector,
      takePrevote: (blockId, choice) =>
        takePrevote({ blockId, choice, sectionId }),
      removePrevote,
      takeVote: (voteData) => takeVote({ ...voteData, sectionId }),
      prevoteData,
    });

  // link logic
  const sectionLinkables = zeck.sections.map((section) =>
    makeSectionLinkable(userAndCompany.activeCompany, section, 'publish'),
  );

  const meetingMinutesLinkables = meetingMinutes.map(
    makeMeetingMinutesLinkable,
  );

  const linkables = [...sectionLinkables, ...meetingMinutesLinkables];

  const linkDrawerState = useLinkDrawer();
  const linkTooltipBehavior = useLinkTooltips({
    linkables: linkables,
    onClickLink: linkDrawerState.openDrawer,
  });

  return (
    <div {...linkTooltipBehavior.linkListeners}>
      <PageWithCommentsSidebar
        commentsState={commentsState}
        publishedSections={zeck.sections}
      >
        <PublishPage
          scrollContainerRef={mergeRefs([
            scrollContainerRef,
            commentsState?.setZeckScrollContainerRef,
          ])}
          commentsState={commentsState}
          brandKitResource={brandKitResource}
          zeck={zeck}
          company={userAndCompany.activeCompany}
          scrollToSectionOrZeckCover={scrollToSectionOrZeckCover}
          onScroll={() => {
            linkTooltipBehavior.onScroll();

            const sectionId = getPrimarySectionInContainer(
              sectionsWithRef.map(({ data: { sectionId }, getEl, setEl }) => ({
                data: { id: sectionId },
                getEl,
                setEl,
              })),
              scrollContainerRef,
            );

            if (sectionId) {
              trackScrollInSection({
                sectionId,
                zeckId: zeck.zeckId,
              });
            }
          }}
        >
          <PublishedZeckView
            scrollContainerRef={scrollContainerRef}
            zeck={zeck}
            company={userAndCompany.activeCompany}
            sectionsWithRef={sectionsWithRef}
            commentsState={commentsState}
            linkables={linkables}
            getZeckCapabilitiesForSection={getZeckCapabilitiesForSection}
            onFilePreview={linkDrawerState.openFilePreview}
          />
        </PublishPage>

        <LinkTooltips
          linkTooltipState={linkTooltipBehavior.linkTooltipState}
          onClickLink={linkDrawerState.openDrawer}
          hideNotFoundTooltip={true}
        />
        <LinkDrawer
          {...{
            linkDrawerState,
            onScrollInDrawer: (linkable) => {
              linkTooltipBehavior.onScroll();

              linkable.type === 'section' &&
                trackScrollInSection({
                  sectionId: linkable.itemId,
                  zeckId: zeck.zeckId,
                });
            },
            renderContent: (linkable, scrollContainerRef) => {
              //this switch should maybe live somewhere else
              switch (linkable.type) {
                case 'pdf':
                  return <PdfFrame pdfUrl={linkable.pdfUrl} />;
                case 'section': {
                  const section = zeck.sections.find(
                    (publishedSection) =>
                      publishedSection.sectionId === linkable.itemId,
                  );
                  if (!section) return null;

                  const { zeckFinalizeVoteCapability, zeckPrevoteCapability } =
                    getZeckCapabilitiesForSection(section.sectionId);

                  return (
                    <SectionView
                      scrollContainerRef={scrollContainerRef}
                      section={section}
                      linkables={linkables}
                      zeckPrevoteCapability={zeckPrevoteCapability}
                      zeckFinalizeVoteCapability={zeckFinalizeVoteCapability}
                      onFilePreview={linkDrawerState.openFilePreview}
                    />
                  );
                }
                case 'meeting-minutes': {
                  const meetingMinutesInstance = meetingMinutes.find(
                    (meetingMinutes) => meetingMinutes.id === linkable.itemId,
                  );

                  if (!meetingMinutesInstance) return null;

                  return (
                    <MeetingMinutesView
                      meetingMinutes={meetingMinutesInstance}
                      additionalContent={
                        isSignedMinutes(meetingMinutesInstance) ? (
                          <MeetingMinutesSignature
                            signature={meetingMinutesInstance.signatures[0]}
                          />
                        ) : null
                      }
                    />
                  );
                }
              }
            },
          }}
        />
      </PageWithCommentsSidebar>
      <Toast {...toast} position="top" />
    </div>
  );
};

export default PublishedSectionPageView;

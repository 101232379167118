import {
  TableMeasurementsBorderOffsets,
  TableMeasurementsRowColumn,
} from './TableMeasurements.js';

export const getCloneTableDimensions = (
  mainTableMeasurements: MainTableMeasurements,
  cloneTableMeasurements: TableMeasurementsBorderOffsets,
): TableMeasurementsRowColumn => {
  const borderOffsetTopCompensation =
    mainTableMeasurements.borderOffsetTop -
    cloneTableMeasurements.borderOffsetTop;

  const borderOffsetLeftCompensation =
    mainTableMeasurements.borderOffsetLeft -
    cloneTableMeasurements.borderOffsetLeft;

  return {
    rowHeights: mainTableMeasurements.rowHeights.map((measurement, i) =>
      i === 0 ? measurement + borderOffsetTopCompensation : measurement,
    ),
    columnWidths: mainTableMeasurements.columnWidths.map((measurement, i) =>
      i === 0 ? measurement + borderOffsetLeftCompensation : measurement,
    ),
  };
};

export type MainTableMeasurements = TableMeasurementsBorderOffsets &
  TableMeasurementsRowColumn;

import { useEffect, useMemo, useState } from 'react';
import { VideoClient } from 'video-client/index.ts';
import { Metadata } from 'video-client/providers/base.ts';

export function useVideoMetadata(url: string) {
  const [videoMetadata, setVideoMetadata] = useState<Metadata | null>(null);

  const videoClient = useMemo(() => new VideoClient(), []);

  useEffect(() => {
    const fetchVideoData = async () => {
      try {
        const metadata = await videoClient.getMetadata(url);
        setVideoMetadata(metadata);
      } catch (error) {
        setVideoMetadata(null);
      }
    };

    fetchVideoData();
  }, [videoClient, url]);

  return { videoMetadata };
}

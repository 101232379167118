import { ReactComponent as ZeckLoadingIcon } from '../images/ZeckLoadingIcon.svg';

import styles from './FullPageLoading.module.scss';
import cx from 'classnames';

const FullPageLoading = () => {
  return (
    <div className={cx(styles.fullPageLoading)}>
      <div className={styles.fullPageLoading__logo}>
        <ZeckLoadingIcon />
      </div>
    </div>
  );
};

export default FullPageLoading;

import { TableBlock } from 'editor-content/TableBlock.ts';
import { useAtom } from 'jotai';
import { AiContext } from '../../pages/zeck/editor/AiChartFlow/createAiContext.ts';
import Toolbar, {
  ToolbarButton,
  ToolbarGroup,
  ToolbarMultiSelectMenu,
} from './Toolbar.tsx';
import {
  EditorErrorMessage,
  useErrorModal,
} from '../../editor/errorModal/ErrorModal.tsx';
import { validateTable } from '../zeck/table/validateTable.js';
import { match } from 'ts-pattern';

type TableFormattingMenuProps = {
  showSyncButton: boolean;
  onClickSync: () => void;
  onFreeze: (block: TableBlock) => void;
  block: TableBlock;
  aiContext: AiContext;
};

export const MERGES_IN_FREEZE_ERROR: EditorErrorMessage = {
  title: "Can't Freeze Row or Column",
  message:
    "Sorry, you can't freeze columns or rows which contain part of a merged cell. Try unmerging the cells and re-syncing or re-uploading.",
};

export const MISSING_CELLS_ERROR: EditorErrorMessage = {
  title: 'Table is Missing Cells',
  message: "Sorry, you can't freeze a table that is missing cells.",
};

const TableFormattingMenu: React.FC<TableFormattingMenuProps> = ({
  showSyncButton,
  onClickSync,
  onFreeze,
  block,
  aiContext,
}) => {
  const [chartMakingTable, setChartMakingTable] = useAtom(
    aiContext.getChartMakingFamily(block),
  );

  const { error, setError } = useErrorModal();

  const showChartButton = !chartMakingTable;

  if (error || (!showChartButton && !showSyncButton)) {
    return null;
  }

  const validateAndFreeze = (
    freeze: Pick<TableBlock, 'frozenColumns' | 'frozenRows'>,
  ) => {
    const newTable: TableBlock = {
      ...block,
      ...freeze,
    };

    return match(validateTable(newTable))
      .with('valid', () => onFreeze(newTable))
      .with('invalid-dimensions', () => setError(MISSING_CELLS_ERROR))
      .with('invalid-freeze', () => setError(MERGES_IN_FREEZE_ERROR));
  };

  return (
    <>
      <Toolbar>
        <ToolbarGroup>
          <ToolbarMultiSelectMenu
            label="Freeze"
            iconName="tableGroup"
            options={[
              {
                label: 'No rows',
                onClick: () => validateAndFreeze({ frozenRows: undefined }),
                selected: block.frozenRows === undefined,
              },
              {
                label: '1 Row',
                onClick: () => {
                  validateAndFreeze({ frozenRows: 1 });
                },
                selected: block.frozenRows === 1,
              },
              {
                label: '2 Rows',
                onClick: () => {
                  validateAndFreeze({ frozenRows: 2 });
                },
                selected: block.frozenRows === 2,
              },
              'divider',
              {
                label: 'No columns',
                onClick: () => validateAndFreeze({ frozenColumns: undefined }),
                selected: block.frozenColumns === undefined,
              },
              {
                label: '1 Column',
                onClick: () => {
                  validateAndFreeze({ frozenColumns: 1 });
                },
                selected: block.frozenColumns === 1,
              },
              {
                label: '2 Columns',
                onClick: () => {
                  validateAndFreeze({ frozenColumns: 2 });
                },
                selected: block.frozenColumns === 2,
              },
            ]}
          />
        </ToolbarGroup>
        <ToolbarGroup>
          {showSyncButton && (
            <ToolbarButton
              iconName="refresh"
              onMouseDown={(e) => {
                // doing this to prevent changing active element focus
                e.preventDefault();
                e.stopPropagation();
                onClickSync();
              }}
              aria-label="sync table"
            >
              Sync
            </ToolbarButton>
          )}
          {showChartButton && (
            <ToolbarButton
              iconName="aiPencil"
              aria-label="generate a chart with ai"
              onClick={() => {
                setChartMakingTable(true);
                // call the ai service, get the chart and add to the editor
              }}
            >
              Generate Chart
            </ToolbarButton>
          )}
        </ToolbarGroup>
      </Toolbar>
    </>
  );
};

export default TableFormattingMenu;

import { Icon, IconName } from 'icons';
import styles from './ActionChip.module.scss';

type ActionChipProps = {
  iconName: IconName;
  label: string;
  onClick: () => void;
};

const ActionChip = ({ iconName, label, onClick }: ActionChipProps) => {
  return (
    <div className={styles.actionChip} onClick={onClick}>
      <Icon name={iconName} className={styles.actionChip__icon} />
      {label}
    </div>
  );
};

export default ActionChip;

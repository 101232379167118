import { TableBlock } from 'editor-content/TableBlock.js';

export const validateTable = (
  table: TableBlock,
): 'valid' | 'invalid-dimensions' | 'invalid-freeze' => {
  const validDimensions = table.data.rows.every(
    (row) => row.cells.length === table.data.rows[0]?.cells.length,
  );

  if (!validDimensions) return 'invalid-dimensions';

  if (
    table.data.merges?.some((merge) => {
      const frozenColumnCount = table.frozenColumns || 0;
      const frozenRowCount = table.frozenRows || 0;

      const mergeIntersectsFrozenRow =
        frozenRowCount > 0 &&
        merge.startRow < frozenRowCount &&
        merge.startRow + merge.rowSpan > frozenRowCount;

      const mergeIntersectionsFrozenColumn =
        frozenColumnCount > 0 &&
        merge.startColumn < frozenColumnCount &&
        merge.startColumn + merge.columnSpan > frozenColumnCount;

      return mergeIntersectsFrozenRow || mergeIntersectionsFrozenColumn;
    })
  )
    return 'invalid-freeze';

  return 'valid';
};

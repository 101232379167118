import React from 'react';
import formattedTableDataFromBlock from './formattedTableData.js';
import { Table } from './Table.js';
import styles from './TableFromBlock.module.scss';
import { TableCell } from './TableCell.js';
import { TableBlock } from 'editor-content/TableBlock.js';
import { getTableGridDimensions } from './getTableGridDimensions.js';

const TableWithFrozenRegions = React.forwardRef<
  HTMLTableElement,
  {
    tableBlock: TableBlock;
    setColumnRefAt: (i: number) => (el: HTMLElement | null) => void;
    setRowRefAt: (i: number) => (el: HTMLElement | null) => void;

    className?: string;
    drawGridlines?: boolean;
  }
>(function TableWithFrozenRegions(
  { className, drawGridlines, setColumnRefAt, setRowRefAt, tableBlock },
  forwardedRef,
) {
  const { numCols, frozenRowCount, frozenColumnCount } =
    getTableGridDimensions(tableBlock);

  const rowData = formattedTableDataFromBlock(tableBlock).rows;
  const rowHeights = tableBlock.data.rowHeights;

  const frozenTableRows = rowData.slice(0, frozenRowCount);
  const bodyTableRows = rowData.slice(frozenRowCount);

  const tableColumns = Array.from({ length: numCols }, (_, i) => ({
    setEl: setColumnRefAt(i),
  }));

  const frozenTableColumns = tableColumns.slice(0, frozenColumnCount);
  const bodyTableColumns = tableColumns.slice(frozenColumnCount);

  return (
    <Table
      className={className}
      ref={forwardedRef}
      drawGridlines={drawGridlines}
    >
      {frozenTableColumns.length > 0 && (
        <colgroup className={styles.frozenColumnBorder}>
          {frozenTableColumns.map(({ setEl }, i) => {
            return <col key={i} ref={setEl} />;
          })}
        </colgroup>
      )}
      {bodyTableColumns.length > 0 && (
        <colgroup>
          {bodyTableColumns.map(({ setEl }, i) => {
            return <col key={i} ref={setEl} />;
          })}
        </colgroup>
      )}

      {frozenTableRows.length > 0 && (
        <thead className={styles.table__theadFrozen}>
          {frozenTableRows.map((row, rowIndex) => {
            const rowHeight = rowHeights?.[rowIndex];
            const style = rowHeight ? { height: rowHeight } : {};

            return (
              <tr key={rowIndex} ref={setRowRefAt(rowIndex)} style={style}>
                {row.cells.map((cell, colIndex) => (
                  <TableCell
                    key={colIndex}
                    format={cell.format}
                    colspan={cell.colspan}
                    rowspan={cell.rowspan}
                    style={cell.style}
                  >
                    <span dangerouslySetInnerHTML={{ __html: cell.content }} />
                  </TableCell>
                ))}
              </tr>
            );
          })}
        </thead>
      )}
      <tbody>
        {bodyTableRows.map((row, arrayIndex) => {
          const rowIndex = arrayIndex + frozenRowCount;

          const rowHeight = rowHeights?.[rowIndex];
          const style = rowHeight ? { height: rowHeight } : {};

          return (
            <tr key={rowIndex} ref={setRowRefAt(rowIndex)} style={style}>
              {row.cells.map((cell, colIndex) => (
                <TableCell
                  key={colIndex}
                  format={cell.format}
                  colspan={cell.colspan}
                  rowspan={cell.rowspan}
                  style={cell.style}
                >
                  <span dangerouslySetInnerHTML={{ __html: cell.content }} />
                </TableCell>
              ))}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
});

export default TableWithFrozenRegions;

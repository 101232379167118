import { atom, useAtom, useSetAtom } from 'jotai';
import Button from '../../design-system/atoms/Button.tsx';
import Modal, {
  ModalActions,
  ModalBody,
  ModalHeader,
} from '../../design-system/organisms/Modal.tsx';
import styles from './ErrorModal.module.scss';

export type EditorErrorMessage = {
  title: string;
  message: string;
};

export const errorAtom = atom<EditorErrorMessage | null>(null);

export const useErrorModal = () => {
  const [error] = useAtom(errorAtom);
  const setError = useSetAtom(errorAtom);

  return { error, setError };
};

const EditorErrorModal: React.FC = () => {
  const { error, setError } = useErrorModal();

  if (!error) {
    return null;
  }

  const onClose = () => {
    setError(null);
  };

  return (
    <Modal isOpen={true} className={styles.errorModal}>
      <ModalHeader>⚠️ {error.title}</ModalHeader>
      <ModalBody>
        <div>{error.message}</div>
      </ModalBody>
      <ModalActions>
        <Button onClick={onClose} size="medium" color="primary">
          Close
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default EditorErrorModal;

import { TableBlock } from 'editor-content/TableBlock.js';

export function getTableGridDimensions(tableBlock: TableBlock): {
  frozenRowCount: 0 | 1 | 2;
  frozenColumnCount: 0 | 1 | 2;
  numRows: number;
  numCols: number;
} {
  const frozenRowCount = tableBlock.frozenRows || 0;
  const frozenColumnCount = tableBlock.frozenColumns || 0;
  const numRows = tableBlock.data.rows.length;
  const numCols = Math.max(
    ...tableBlock.data.rows.map(({ cells }) => cells.length),
  );
  return { frozenRowCount, frozenColumnCount, numRows, numCols };
}

import cx from 'classnames';
import { Link } from 'react-router-dom';
import TransparentInput from '../../../design-system/molecules/TransparentInput.tsx';
import WithTooltip from '../../../design-system/organisms/WithTooltip.tsx';
import useAllowsInsideClick from '../../../services/useAllowsInsideClick.tsx';
import styles from './ZeckCard.module.scss';

export type ZeckCardProps = {
  name: string;
  coverImage: React.ReactNode;
  to: string;
  isRenaming?: boolean;
  onRename?: (name: string) => void;
  bottomSlot?: React.ReactNode;
  rightSlot?: React.ReactNode;
  className?: string;
};

const ZeckCard = ({
  name,
  coverImage,
  to,
  isRenaming,
  onRename = () => {
    return;
  },
  bottomSlot,
  rightSlot,
  className,
}: ZeckCardProps) => {
  const [ref, onClickContainer] = useAllowsInsideClick<HTMLAnchorElement>(
    () => {},
  );

  return (
    <Link
      {...{
        to: to,
        className: cx(styles.zeckCard, className),
        ref,
        onClick: onClickContainer,
        // this allows user to click/drag to select text in the input without the browser seeing it as a link drag
        draggable: false,
        'data-testid': 'zeck-card',
      }}
    >
      <div className={styles.zeckCard__cover}>{coverImage}</div>
      <div className={styles.zeckCard__bottom}>
        {isRenaming ? (
          <TransparentInput
            aria-label="zeck name"
            className={styles.zeckCard__caption}
            value={name}
            onSubmit={(value) => {
              onRename(value);
            }}
          />
        ) : (
          <WithTooltip<HTMLDivElement> text={name}>
            {(ref, listeners) => (
              <div
                className={styles.zeckCard__caption}
                data-testid="zeck-name"
                ref={ref}
                {...listeners}
              >
                {name}
              </div>
            )}
          </WithTooltip>
        )}
        {bottomSlot && (
          <div className={styles.zeckCard__subcaption}>{bottomSlot}</div>
        )}
        {rightSlot && <div className={styles.zeckCard__right}>{rightSlot}</div>}
      </div>
    </Link>
  );
};

export default ZeckCard;

import { VideoBlock } from 'editor-content/Block.js';
import styles from './Video.module.scss';
import { useVideoMetadata } from './useVideoMetadata.ts';

type VideoProps = {
  className?: string;
  block: VideoBlock;
};

export default function Video({ block, className }: VideoProps) {
  const { videoMetadata } = useVideoMetadata(block.url);

  if (!videoMetadata) return null;

  return (
    <div id={block.id} className={className} data-testid="video-block">
      {
        <iframe
          title={videoMetadata.title}
          className={styles.player}
          src={videoMetadata.iframe.src}
          frameBorder="0"
          allow={videoMetadata.iframe.allow}
          allowFullScreen
        ></iframe>
      }
    </div>
  );
}

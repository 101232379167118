import { ChartBlock } from 'editor-content/ChartBlock.ts';
import { TableBlock } from 'editor-content/TableBlock.js';
import { useAtom } from 'jotai';
import React, { useState } from 'react';
import IconButton from '../../../../design-system/atoms/IconButton.tsx';
import ActionChip from '../../../../design-system/molecules/ActionChip.tsx';
import isChartWorthy from '../../../../design-system/zeck/table/isChartWorthy.ts';
import { WideWidthBlockContainer } from '../../../../design-system/zeck/TableView.tsx';
import DetectsOutsideClick from '../../../../junkDrawer/DetectsOutsideClick.tsx';
import mergeRefs from '../../../../junkDrawer/mergeRefs.ts';
import {
  AiChartFlow,
  useChartPromptSubmission,
} from '../AiChartFlow/AiChartFlow.tsx';
import { AiContext } from '../AiChartFlow/createAiContext.ts';
import { useGenerateAiChartFlowProps } from '../AiChartFlow/useGenerateAiChartFlowProps.tsx';
import BlockActions from './BlockActions.ts';
import SelectableDiv from './SelectableDiv.tsx';
import styles from './TableEditableWithKeyboard.module.scss';
import TableFromBlock from '../../../../design-system/zeck/table/TableFromBlock.tsx';
type TableEditableWithKeyboardProps = {
  className?: string;
  isInBlockSelection: boolean;
  'data-testid'?: string;
  onInsertChartBlock: (chart: ChartBlock) => void;
  aiContext: AiContext;
} & BlockActions<TableBlock>;

const AiChartMenu = ({
  setIsOpen,
  setIsAddingChart,
}: {
  setIsOpen: (isOpen: boolean) => void;
  setIsAddingChart: (isAddingChart: boolean) => void;
}) => {
  return (
    <DetectsOutsideClick onClick={() => setIsOpen(false)}>
      {(ref) => (
        <div className={styles.aiChartMenuWrapper} ref={ref}>
          <div className={styles.aiChartMenuHeader}>Ask Zeck AI to:</div>

          <div>
            <ActionChip
              iconName="aiChart"
              label="Create an interesting chart"
              onClick={() => {
                setIsAddingChart(true);
                setIsOpen(false);
              }}
            />
          </div>
        </div>
      )}
    </DetectsOutsideClick>
  );
};

const AiChartMenuButton = ({
  onAddChart: setAddingChart,
}: {
  onAddChart: (isAddingChart: boolean) => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <IconButton
        aria-label="Ask Zeck AI to create an interesting chart"
        name="aiGradiantStar"
        onClick={() => setIsOpen(!isOpen)}
        className={styles.aiChartMenuButton}
      />
      {isOpen && (
        <AiChartMenu setIsOpen={setIsOpen} setIsAddingChart={setAddingChart} />
      )}
    </div>
  );
};

const MemoizedTable = React.memo(TableFromBlock);

export default React.forwardRef<HTMLElement, TableEditableWithKeyboardProps>(
  function TableEditableWithKeyboard(
    {
      className,
      block,
      selection,
      onSelect,
      isInBlockSelection,
      'data-testid': dataTestid,
      onInsertChartBlock,
      aiContext,
    },
    forwardedRef,
  ) {
    const [addingChart, setAddingChart] = useAtom(
      aiContext.getChartMakingFamily(block),
    );

    const {
      forceTableSelection,
      setForceTableSelection,
      isChartWizardVisible,
      generateAiChart,
      setChartState,
      ...aiChartFlowProps
    } = useGenerateAiChartFlowProps({
      setAddingChart,
      onInsertChartBlock,
      fromBlock: block,
      selection,
      aiContext,
    });

    const handleSubmitChartPrompt = useChartPromptSubmission({
      generateAiChart,
      setChartState,
      setForceTableSelection,
    });

    const chartWorthy = isChartWorthy(block);

    const chartInProgress = addingChart && isChartWizardVisible;

    const onAddChart = (isAddingChart: boolean) => {
      onSelect({ anchorOffset: 0, focusOffset: 0 });
      setAddingChart(isAddingChart);
      handleSubmitChartPrompt('create an interesting chart from this table');
    };

    return (
      <WideWidthBlockContainer>
        <div className={styles.tableAndAiMenuContainer}>
          <SelectableDiv
            square
            className={className}
            onSelect={onSelect}
            internalSelection={selection}
            isInBlockSelection={isInBlockSelection || forceTableSelection}
            data-testid={dataTestid}
            ref={mergeRefs([forwardedRef])}
          >
            <MemoizedTable block={block} />
          </SelectableDiv>

          {chartWorthy && !chartInProgress && (
            <AiChartMenuButton onAddChart={onAddChart} />
          )}
        </div>

        {chartInProgress && (
          <AiChartFlow
            {...aiChartFlowProps}
            setForceTableSelection={setForceTableSelection}
            generateAiChart={generateAiChart}
            setChartState={setChartState}
          />
        )}
      </WideWidthBlockContainer>
    );
  },
);

import YouTubeVideo from './youtube.js';
import VimeoVideo from './vimeo.js';

const providers = [YouTubeVideo, VimeoVideo] as const;

export function match(url: string) {
  const Provider = providers.find((p) => p.match(url));

  if (!Provider) {
    throw new Error('No matching providers');
  }

  return new Provider(url);
}

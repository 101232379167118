import { AnimatePresence, motion } from 'framer-motion';
import { Result } from '../services/useFetch/useFetch.ts';

const WithResultLoading = <T,>(props: {
  result: Result<T>;
  duration: number;
  children: (value: T) => React.ReactNode;
  renderLoading: (out: boolean) => React.ReactNode;
  renderError?: () => React.ReactNode;
}) => {
  const { result, children, renderLoading, renderError = () => null } = props;

  const durationSeconds = props.duration / 1000;

  switch (result.type) {
    case 'error':
      return <>{renderError()}</>;
    case 'loading':
    case 'success':
      return (
        <div>
          <AnimatePresence>
            {result.type === 'success' && <>{children(result.data)}</>}

            {result.type === 'loading' && (
              <motion.div
                initial={{ opacity: 1 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                  duration: durationSeconds,
                  ease: 'easeOut',
                }}
              >
                {renderLoading(false)}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      );
  }
};

export default WithResultLoading;

export type Metadata = {
  url: string;
  thumbnail: string;
  author: string;
  title: string;
  html: string;
  iframe: {
    src: string;
    allow: string;
  };
};

export default abstract class BaseVideo {
  abstract getMetadata(): Promise<Metadata>;

  getIframeAttributes(iframeHtml: string) {
    const srcMatch = iframeHtml.match(/src="([^"]+)"/);
    const allowMatch = iframeHtml.match(/allow="([^"]+)"/);

    return {
      src: srcMatch?.[1] ?? '',
      allow: allowMatch?.[1] ?? '',
    };
  }
}

import React, { SVGProps } from 'react';
import { cx } from 'styling';

export function SendIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      style={{ display: 'block' }}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={cx(props.className)}
    >
      <g transform="matrix(1.5,0,0,1.5,0,0)">
        <g transform="matrix(0.957847,0,0,0.954239,0.341415,0.367227)">
          <path
            d="M6.857,9.142L0.571,6.285L15.429,0.57L9.714,15.428L6.857,9.142ZM6.857,9.142L10.286,5.713"
            fill="transparent"
            stroke="currentColor"
            strokeWidth="1.71"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
}

import React from 'react';
import { TableBlock } from 'editor-content/TableBlock.js';
import TableFromBlock from './table/TableFromBlock.tsx';
import cx from 'classnames';
import styles from './TableView.module.scss';

export const WideWidthBlockContainer: React.FC<{
  children: React.ReactNode;
  id?: string;
  className?: string;
}> = ({ children, id, className }) => {
  return (
    <div id={id} className={cx(className, styles.wideWidthBlockContainer)}>
      {children}
    </div>
  );
};

export const TableScrollShadowContainer = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<'div'>
>(function TableScrollShadowContainer(
  { children, className, ...otherProps },
  forwardedRef,
) {
  return (
    <div
      ref={forwardedRef}
      {...otherProps}
      className={cx(styles.tableShadowScrollContainer, className)}
    >
      {children}
    </div>
  );
});

type TableViewProps = {
  block: TableBlock;
  className?: string;
};

function TableView({ className, block }: TableViewProps) {
  return (
    <WideWidthBlockContainer id={block.id} className={className}>
      <TableFromBlock block={block} noBorder />
    </WideWidthBlockContainer>
  );
}

export default TableView;

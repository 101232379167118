import { PublishedSection } from '../../../../types.ts';
import { CommentsState } from './commentsSidebar/useComments/useComments.ts';

const UUID_REGEX =
  '[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}';

const getCommentAnchorIdFromHash = (hash: string) => {
  const regex = new RegExp(`^#comment-thread-(${UUID_REGEX})$`);
  const match = hash.match(regex);
  return match ? match[1] : null;
};

const COMMENT_TOAST_DURATION_MS = 3000;

const handleCommentAnchor = (
  commentAnchorId: string,
  commentsState: NonNullable<CommentsState>,
  section: PublishedSection,
  showToast: (message: string, duration?: number) => void,
) => {
  const comment = commentsState
    .forSection(section)
    .findComment(commentAnchorId);

  if (!comment) {
    showToast('Comment has been deleted.', COMMENT_TOAST_DURATION_MS);
    return;
  }

  commentsState.forSection(section).openSectionComments();
  commentsState
    .forSection(section)
    .selectedComment.selectCommentThread(commentAnchorId, true);
  commentsState.forSection(section).openComment(commentAnchorId);
  if (comment.resolved) {
    commentsState.setFilter('resolved');
    showToast(
      'Comment has been marked as resolved. Remove filter to see open comments.',
      COMMENT_TOAST_DURATION_MS,
    );
  }
};

export default function publishedSectionPageHandleUrlHash(
  commentsState: CommentsState,
  section: PublishedSection | undefined,
  showToast: (message: string, duration?: number) => void,
) {
  if (!commentsState || !commentsState.isCommentsLoaded || !section) {
    return;
  }
  const currentAnchor = window.location.hash;

  // looks for #comments hash, if exists opens sidebar and remove hash from url
  if (currentAnchor === '#comments') {
    window.location.hash = '';

    commentsState.forSection(section).openSectionComments();
    return;
  }

  // looks for #comment-thread-(${UUID_REGEX})
  const commentAnchorId = getCommentAnchorIdFromHash(currentAnchor) || null;
  if (commentAnchorId) {
    window.location.hash = '';
    handleCommentAnchor(commentAnchorId, commentsState, section, showToast);
  }
}

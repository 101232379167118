export type TableMeasurementsRowColumn = {
  rowHeights: number[];
  columnWidths: number[];
};

export type TableMeasurementsBorderOffsets = {
  borderOffsetLeft: number;
  borderOffsetTop: number;
};

/**
 * Browser render the external border of tables outside the cells. We want to measure this by comparing
 * the position of the table element to the first column and row elements.
 *
 * This allows us to align a table with a clone when the clone region does not contain the outer border.
 * @param tableRefs Refs for the table to measure
 */
export const measureTableBorderOffsets = (tableRefs: {
  getTableEl: () => HTMLElement | null;
  getRowElAt: (index: number) => HTMLElement | null;
  getColumnElAt: (index: number) => HTMLElement | null;
}): TableMeasurementsBorderOffsets => {
  const mainTable = tableRefs.getTableEl();
  const firstColumn = tableRefs.getColumnElAt(0);
  const firstRow = tableRefs.getRowElAt(0);

  if (!mainTable || !firstRow || !firstColumn) {
    console.warn(
      'TableBorderOffset calculation could not find one of table, row, or column ref',
    );
    return {
      borderOffsetLeft: 0,
      borderOffsetTop: 0,
    };
  }

  const mainTableRect = mainTable.getBoundingClientRect();
  const firstColumnRect = firstColumn.getBoundingClientRect();
  const firstRowRect = firstRow.getBoundingClientRect();

  const borderOffsetLeft = firstColumnRect.x - mainTableRect.x;
  const borderOffsetTop = firstRowRect.y - mainTableRect.y;

  return {
    borderOffsetLeft,
    borderOffsetTop,
  };
};

/**
 * Measures rows and column widths of a table.
 *
 * @param tableRefs Refs for the table elements to measure
 * @param tableGridDimensions Dimensions of the table data
 */
export const measureTableRowsAndColumns = (
  tableRefs: {
    getRowElAt: (index: number) => HTMLElement | null;
    getColumnElAt: (index: number) => HTMLElement | null;
  },
  tableGridDimensions: {
    rowCount: number;
    columnCount: number;
  },
): TableMeasurementsRowColumn => {
  const columnWidths = Array.from(
    { length: tableGridDimensions.columnCount },
    (_, colIndex) => {
      const colSourceEl = tableRefs.getColumnElAt(colIndex);
      if (!colSourceEl) return 0;

      // Safari does not have bounding client rect width for col elements. It does, however, have offsetWidth
      return (
        colSourceEl.getBoundingClientRect().width || colSourceEl.offsetWidth
      );
    },
  );

  const rowHeights = Array.from(
    { length: tableGridDimensions.rowCount },
    (_, rowIndex) => {
      const rowSourceEl = tableRefs.getRowElAt(rowIndex);
      if (!rowSourceEl) return 0;

      return rowSourceEl.getBoundingClientRect().height;
    },
  );

  return { rowHeights, columnWidths };
};

import styles from './TableCell.module.scss';
import {
  TableBlockCellFormat,
  TableBorderType,
} from 'editor-content/TableBlock.js';
import compact from 'lodash/compact.js';
import React from 'react';
import cx from 'classnames';

const getCellWrapClassName = (
  format: TableBlockCellFormat,
): string | undefined => {
  switch (format.wrap) {
    case 'wrap':
      return styles.cell__wrap;
    case 'clip':
      return styles.cell__clip;
  }
};

const getClassNameFromBorder = (
  border: TableBorderType,
  position: 'top' | 'right' | 'bottom' | 'left',
) => {
  if (!border) {
    return undefined;
  }
  return styles[`cell__border__${position}__${border}`];
};

const horizontalAlignClassNames = {
  left: styles.cell__alignHorizontal__left,
  center: styles.cell__alignHorizontal__center,
  right: styles.cell__alignHorizontal__right,
  none: undefined,
} as const;

const alignVerticalClassNames = {
  top: styles.cell__alignVertical__top,
  middle: styles.cell__alignVertical__middle,
  bottom: styles.cell__alignVertical__bottom,
  none: undefined,
} as const;

const getCellBorderClassNames = (
  format: TableBlockCellFormat,
): Array<string> => {
  return compact([
    getClassNameFromBorder(format.border?.top, 'top'),
    getClassNameFromBorder(format.border?.bottom, 'bottom'),
    getClassNameFromBorder(format.border?.right, 'right'),
    getClassNameFromBorder(format.border?.left, 'left'),
  ]);
};

export const TableCell: React.FC<{
  children: React.ReactNode;
  format?: TableBlockCellFormat;
  colspan?: number;
  rowspan?: number;
  style?: { minWidth: string; maxWidth: string };
}> = (props) => {
  return (
    <td
      style={{
        ...(props.style ?? {}),
        ...(props.format?.bgColor
          ? { backgroundColor: props.format.bgColor }
          : {}),
      }}
      className={cx(
        styles.cell,
        horizontalAlignClassNames[props.format?.alignHorizontal ?? 'none'],
        alignVerticalClassNames[props.format?.alignVertical ?? 'none'],
        props.format && getCellBorderClassNames(props.format),
      )}
      colSpan={props.colspan}
      rowSpan={props.rowspan}
    >
      <div className={cx(props.format && getCellWrapClassName(props.format))}>
        {props.children}
      </div>
    </td>
  );
};

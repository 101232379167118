import WithResultLoading from '../../result/WithResultLoading.tsx';
import { Result } from '../../services/useFetch/useFetch.ts';
import { ZeckLite } from '../../types.ts';
import { ZeckFolderWithCount } from '../../types/ZeckFolder.ts';
import { UserAndCompany } from '../../userAndCompany/FetchUserAndCompany.tsx';
import LoaderSpinnerOverlay from '../LoaderSpinnerOverlay.tsx';
import PageLayout, { PageType } from '../PageLayout.tsx';
import PublishedZeckListEmpty from './PublishedZeckListEmpty.tsx';
import ZecksHomepageContent from './ZecksHomepageContent.tsx';

type MemberHomePageProps = {
  userAndCompany: UserAndCompany;
  onClickLogout: () => void;
  zecksResult: Result<ZeckLite[]>;
  foldersResult: Result<ZeckFolderWithCount[]>;
  activePage: PageType;
  pageTitle: string;
  isHome: boolean;
};

const noop = () => {};
const asyncNoop = async () => {};

const MemberHomePage = ({
  userAndCompany,
  onClickLogout,
  zecksResult,
  foldersResult,
  activePage,
  pageTitle,
  isHome,
}: MemberHomePageProps) => {
  return (
    <PageLayout
      {...{
        title: pageTitle,
        activePage,
        userAndCompany,
        onClickLogout,
        zeckFoldersResult: foldersResult,
      }}
    >
      <WithResultLoading
        result={zecksResult}
        duration={400}
        renderLoading={() => <LoaderSpinnerOverlay />}
      >
        {(zecks) => (
          <ZecksHomepageContent
            zecks={zecks}
            folders={foldersResult}
            onClickDeleteZeck={noop}
            onClickDuplicateZeck={noop}
            onChangeZeck={asyncNoop}
            onMoveZeck={asyncNoop}
            pageTitle={pageTitle}
            isHome={isHome}
            EmptyZeckNode={<PublishedZeckListEmpty />}
          />
        )}
      </WithResultLoading>
    </PageLayout>
  );
};

export default MemberHomePage;

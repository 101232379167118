import React, { SVGProps } from 'react';

export function AiGradiantStarIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="26"
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.14838 3.93856L8.31532 4.02203C8.99 4.3698 9.53949 4.91929 9.88726 5.59397L9.97073 5.7609C10.3046 6.42168 11.2505 6.42168 11.5914 5.7609L11.6748 5.59397C12.0226 4.91929 12.5721 4.3698 13.2468 4.02203L13.4137 3.93856C14.0745 3.6047 14.0745 2.65875 13.4137 2.31792L13.2468 2.23446C12.5721 1.88668 12.0226 1.3372 11.6748 0.662513L11.5914 0.49558C11.2575 -0.165193 10.3115 -0.165193 9.97073 0.49558L9.88726 0.662513C9.53949 1.3372 8.99 1.88668 8.31532 2.23446L8.14838 2.31792C7.48761 2.65179 7.48761 3.59774 8.14838 3.93856Z"
        fill="url(#paint0_linear_15798_34921)"
      />
      <path
        d="M9.46298 10.4559C10.1446 10.1081 10.1446 9.14128 9.46298 8.79351L8.44747 8.27184C7.54325 7.80583 6.80597 7.06854 6.33995 6.16432L5.81829 5.14882C5.47051 4.46718 4.5037 4.46718 4.15592 5.14882L3.63426 6.16432C3.16824 7.06854 2.43095 7.80583 1.52673 8.27184L0.51123 8.79351C-0.17041 9.14128 -0.17041 10.1081 0.51123 10.4559L1.52673 10.9775C2.43095 11.4436 3.16824 12.1808 3.63426 13.0851L4.15592 14.1006C4.5037 14.7822 5.47051 14.7822 5.81829 14.1006L6.33995 13.0851C6.80597 12.1808 7.54325 11.4436 8.44747 10.9775L9.46298 10.4559Z"
        fill="url(#paint1_linear_15798_34921)"
      />
      <path
        d="M22.5115 14.6153L21.4821 14.0867C20.2371 13.4537 19.2424 12.4591 18.6095 11.214L18.0809 10.1846C17.6148 9.26648 16.6828 8.69613 15.6534 8.69613C14.624 8.69613 13.6919 9.26648 13.2259 10.1846L12.6973 11.214C12.0643 12.4591 11.0697 13.4537 9.82466 14.0867L8.79525 14.6153C7.87712 15.0813 7.30677 16.0133 7.30677 17.0427C7.30677 18.0722 7.87712 19.0042 8.79525 19.4702L9.82466 19.9988C11.0697 20.6318 12.0643 21.6264 12.6973 22.8715L13.2259 23.9009C13.6919 24.819 14.624 25.3894 15.6534 25.3894C16.6828 25.3894 17.6148 24.819 18.0809 23.9009L18.6095 22.8715C19.2424 21.6264 20.2371 20.6318 21.4821 19.9988L22.5115 19.4702C23.4296 19.0042 24 18.0722 24 17.0427C24 16.0133 23.4296 15.0813 22.5115 14.6153ZM21.5656 17.6131L20.5362 18.1417C18.8947 18.9764 17.587 20.284 16.7524 21.9255L16.2237 22.9549C16.0638 23.2679 15.7716 23.3027 15.6534 23.3027C15.5351 23.3027 15.243 23.2679 15.083 22.9549L14.5544 21.9255C13.7197 20.284 12.4121 18.9764 10.7706 18.1417L9.7412 17.6131C9.4282 17.4531 9.39342 17.161 9.39342 17.0427C9.39342 16.9245 9.4282 16.6324 9.7412 16.4724L10.7706 15.9438C12.4121 15.1091 13.7197 13.8015 14.5544 12.16L15.083 11.1306C15.243 10.8176 15.5351 10.7828 15.6534 10.7828C15.7716 10.7828 16.0638 10.8176 16.2237 11.1306L16.7524 12.16C17.587 13.8015 18.8947 15.1091 20.5362 15.9438L21.5656 16.4724C21.8786 16.6324 21.9133 16.9245 21.9133 17.0427C21.9133 17.161 21.8786 17.4531 21.5656 17.6131Z"
        fill="url(#paint2_linear_15798_34921)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_15798_34921"
          x1="-7.75221"
          y1="8.20099"
          x2="9.01124"
          y2="32.7299"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#3E7BFA" />
          <stop offset="1" stop-color="#6600CC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_15798_34921"
          x1="-7.75221"
          y1="8.20099"
          x2="9.01124"
          y2="32.7299"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#3E7BFA" />
          <stop offset="1" stop-color="#6600CC" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_15798_34921"
          x1="-7.75221"
          y1="8.20099"
          x2="9.01124"
          y2="32.7299"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#3E7BFA" />
          <stop offset="1" stop-color="#6600CC" />
        </linearGradient>
      </defs>
    </svg>
  );
}

import React, { SVGProps } from 'react';

export function AiChartIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.8074 5.41874L12.6434 5.79607C12.6177 5.85751 12.5745 5.91 12.5191 5.94692C12.4637 5.98384 12.3986 6.00354 12.332 6.00354C12.2655 6.00354 12.2004 5.98384 12.145 5.94692C12.0896 5.91 12.0463 5.85751 12.0207 5.79607L11.8567 5.41874C11.5684 4.75122 11.0403 4.21602 10.3767 3.91874L9.8707 3.69274C9.80931 3.6645 9.75731 3.61925 9.72085 3.56236C9.6844 3.50546 9.66502 3.43931 9.66502 3.37174C9.66502 3.30417 9.6844 3.23801 9.72085 3.18112C9.75731 3.12422 9.80931 3.07897 9.8707 3.05074L10.3487 2.83807C11.029 2.53232 11.5661 1.97732 11.8494 1.2874L12.018 0.88007C12.0428 0.816972 12.086 0.762802 12.142 0.72462C12.198 0.686438 12.2642 0.666016 12.332 0.666016C12.3998 0.666016 12.466 0.686438 12.522 0.72462C12.5781 0.762802 12.6213 0.816972 12.646 0.88007L12.8147 1.28674C13.0977 1.97679 13.6346 2.53202 14.3147 2.83807L14.7934 3.0514C14.8546 3.07972 14.9064 3.12496 14.9427 3.18179C14.979 3.23861 14.9983 3.30463 14.9983 3.37207C14.9983 3.43951 14.979 3.50553 14.9427 3.56235C14.9064 3.61917 14.8546 3.66442 14.7934 3.69274L14.2867 3.91807C13.6232 4.21565 13.0954 4.75109 12.8074 5.41874ZM0.332031 2.66674C0.332031 2.48993 0.402269 2.32036 0.527293 2.19533C0.652318 2.07031 0.821887 2.00007 0.998698 2.00007H8.33203V3.3334H1.66536V12.6667H12.332V7.3334H13.6654V13.3334C13.6654 13.5102 13.5951 13.6798 13.4701 13.8048C13.3451 13.9298 13.1755 14.0001 12.9987 14.0001H0.998698C0.821887 14.0001 0.652318 13.9298 0.527293 13.8048C0.402269 13.6798 0.332031 13.5102 0.332031 13.3334V2.66674ZM3.66536 8.66674H4.9987V11.3334H3.66536V8.66674ZM6.33203 4.66674H7.66536V11.3334H6.33203V4.66674ZM8.9987 6.66674H10.332V11.3334H8.9987V6.66674Z"
        fill="url(#paint0_linear_15868_35059)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_15868_35059"
          x1="-4.40531"
          y1="4.97303"
          x2="3.84769"
          y2="19.0246"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#3E7BFA" />
          <stop offset="1" stop-color="#6600CC" />
        </linearGradient>
      </defs>
    </svg>
  );
}

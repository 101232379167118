// AiChartFlow -creates a chart from a table from user prompt

import { ChartBlockData } from 'editor-content/ChartBlock.ts';
import { isError } from 'lodash';
import { useEffect } from 'react';
import {
  AiChartGenerateResponse,
  isAiChartGenerateResponseError,
} from '../../../../api/endpoints/createAiApi.ts';
import { logError } from '../../../../logging/logger.ts';
import styles from './AiChartFlow.module.scss';
import {
  AiChartFlowError,
  AiChartFlowErrorState,
} from './AiChartFlowError.tsx';
import {
  AiChartFlowLoading,
  AiChartFlowLoadingState,
} from './AiChartFlowLoading.tsx';
import {
  AiChartFlowPrompting,
  AiChartFlowPromptingState,
} from './AiChartFlowPrompting.tsx';
import {
  AiChartFlowReviewing,
  AiChartFlowReviewingState,
  AiChartFlowEditingState,
} from './AiChartFlowReviewing.tsx';
import DetectsOutsideClick from '../../../../junkDrawer/DetectsOutsideClick.tsx';

type UseChartPromptSubmissionProps = {
  generateAiChart: (prompt: string) => Promise<AiChartGenerateResponse>;
  setChartState: (state: AiChartFlowState) => void;
  setForceTableSelection: (force: boolean) => void;
};

export const useChartPromptSubmission = ({
  generateAiChart,
  setChartState,
  setForceTableSelection,
}: UseChartPromptSubmissionProps) => {
  const handleSubmitChartPrompt = async (prompt: string): Promise<void> => {
    setChartState({ type: 'loading' });

    try {
      const chartResponse = await generateAiChart(prompt);

      const newState = isAiChartGenerateResponseError(chartResponse)
        ? {
            type: 'error' as const,
            errorType: chartResponse.errorType,
          }
        : {
            type: 'reviewing' as const,
            chart: chartResponse.chart,
            lastSchema: chartResponse.lastSchema,
            pastMessages: chartResponse.pastMessages,
          };

      setChartState(newState);
      setForceTableSelection(false);
    } catch (e) {
      if (isError(e)) {
        logError(e);
      }

      setChartState({
        type: 'error',
        errorType: 'unknown',
      });
    }
  };

  return handleSubmitChartPrompt;
};

// call the ai service, get the chart and add to the editor
export type AiChartFlowProps = {
  generateAiChart: (prompt: string) => Promise<AiChartGenerateResponse>;
  onClose: () => void;
  setForceTableSelection: (force: boolean) => void;
  recordAnalytics: (type: 'accept' | 'discard') => Promise<void>;
  onAccept: (
    chartConfig: ChartBlockData,
    base64ImageData: string,
    pastMessages: string[],
  ) => Promise<void>;
  chartState: AiChartFlowState;
  setChartState: (state: AiChartFlowState) => void;
};

export type AiChartFlowState =
  | AiChartFlowPromptingState
  | AiChartFlowLoadingState
  | AiChartFlowReviewingState
  | AiChartFlowEditingState
  | AiChartFlowErrorState;

export const AiChartFlow: React.FC<AiChartFlowProps> = ({
  generateAiChart,
  onClose,
  setForceTableSelection,
  recordAnalytics,
  onAccept,
  chartState,
  setChartState,
}) => {
  useEffect(() => {
    setForceTableSelection(true);

    return onClose;
  }, [setForceTableSelection, onClose]);

  const handleSubmitChartPrompt = useChartPromptSubmission({
    generateAiChart,
    setChartState,
    setForceTableSelection,
  });

  return (
    <DetectsOutsideClick
      onClick={() => {
        if (chartState.type === 'prompting' || chartState.type === 'editing') {
          onClose();
        }
      }}
    >
      {(ref) => (
        <div
          ref={ref}
          className={styles.chartCreationContainer}
          data-testid="ai-chart-wizard"
        >
          {chartState.type === 'prompting' && (
            <AiChartFlowPrompting
              onSubmitChartPrompt={handleSubmitChartPrompt}
            />
          )}

          {chartState.type === 'loading' && <AiChartFlowLoading />}

          {chartState.type === 'error' && (
            <AiChartFlowError
              onClose={onClose}
              errorType={chartState.errorType}
            />
          )}

          {(chartState.type === 'reviewing' ||
            chartState.type === 'editing') && (
            <AiChartFlowReviewing
              chart={chartState.chart}
              pastMessages={chartState.pastMessages}
              onDiscard={async () => {
                onClose();
                await recordAnalytics('discard');
              }}
              onAccept={async (base64ImageData) => {
                await onAccept(
                  chartState.chart,
                  base64ImageData,
                  chartState.pastMessages,
                );
                onClose();
                await recordAnalytics('accept');
              }}
              generateAiChart={generateAiChart}
              setChartState={setChartState}
              showAcceptRejectButtons={chartState.type === 'reviewing'}
            />
          )}
        </div>
      )}
    </DetectsOutsideClick>
  );
};

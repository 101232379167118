import { useDocumentTitle } from '../../junkDrawer/useDocumentTitleFromResult.ts';
import { getZeckBaseUrl, zeckEditPath } from '../../services/Paths.js';
import { Result } from '../../services/useFetch/useFetch.js';
import { ZeckFolderWithCount } from '../../types/ZeckFolder.js';
import { ZeckLite } from '../../types/ZeckLite.js';
import LoaderSpinnerOverlay from '../LoaderSpinnerOverlay.tsx';
import { DraggableZeckListItem } from './FolderDragAndDrop.js';
import ZeckList, { ZeckListTitle, ZeckListWrapper } from './ZeckList.js';
import styles from './ZecksHomepageContent.module.scss';
import ZeckCardWithMenu from './card/ZeckCardWithMenu.js';
import FolderList from './folderCard/FolderList.tsx';

type ZeckListBodyProps = {
  zecks: ZeckLite[];
  onClickDeleteZeck: (id: string) => void;
  onClickDuplicateZeck: (id: string, duplicatePermissions: boolean) => void;
  onChangeZeck: (zeck: ZeckLite, optimistic?: boolean) => Promise<void>;
  onMoveZeck: (zeckId: string, folderId: string | null) => Promise<void>;
  folders: Result<ZeckFolderWithCount[]>;
};

const ZeckListBody = ({
  zecks,
  folders,
  onMoveZeck,
  onClickDeleteZeck,
  onClickDuplicateZeck,
  onChangeZeck,
}: ZeckListBodyProps) => {
  const zecksSortedByCreatedAt = zecks.sort(
    (a, b) => b.createdAt - a.createdAt,
  );

  return (
    <ZeckList>
      {zecksSortedByCreatedAt.map((zeck) => (
        <DraggableZeckListItem id={zeck.id} key={zeck.id}>
          <ZeckCardWithMenu
            {...{
              zeck,
              folders,
              to:
                zeck.isAdmin || zeck.isContributor
                  ? zeckEditPath(zeck)
                  : `/${getZeckBaseUrl(zeck)}`,
              onClickDelete() {
                onClickDeleteZeck(zeck.id);
              },
              onClickDuplicate(duplicatePermissions) {
                onClickDuplicateZeck(zeck.id, duplicatePermissions);
              },
              onChangeZeckName(name) {
                onChangeZeck({
                  ...zeck,
                  name: name || 'Untitled',
                });
              },
              onMoveZeck: (folderId) => onMoveZeck(zeck.id, folderId),
              usePortal: true,
            }}
          />
        </DraggableZeckListItem>
      ))}
    </ZeckList>
  );
};

const ZeckHomeWithFolderList: React.FC<{
  zecks: ZeckLite[];
  folders: Result<ZeckFolderWithCount[]>;
  onClickDeleteZeck: (id: string) => void;
  onClickDuplicateZeck: (id: string, duplicatePermissions: boolean) => void;
  onChangeZeck: (zeck: ZeckLite, optimistic?: boolean) => Promise<void>;
  onMoveZeck: (zeckId: string, folderId: string | null) => Promise<void>;
}> = ({
  zecks,
  folders,
  onClickDeleteZeck,
  onClickDuplicateZeck,
  onChangeZeck,
  onMoveZeck,
}) => {
  if (!(folders.type === 'success')) {
    return null;
  }

  return (
    <>
      <FolderList folders={folders.data} />

      {!!zecks.length && (
        <>
          <div className={styles.zecksHomepageContent__unfoldered_heading}>
            Unfoldered Zecks
          </div>
          <ZeckListBody
            {...{
              zecks,
              folders,
              onMoveZeck,
              onClickDeleteZeck,
              onClickDuplicateZeck,
              onChangeZeck,
            }}
          />
        </>
      )}
    </>
  );
};

const ZecksHomepageContent: React.FC<{
  zecks: ZeckLite[];
  folders: Result<ZeckFolderWithCount[]>;
  onClickDeleteZeck: (id: string) => void;
  onClickDuplicateZeck: (id: string, duplicatePermissions: boolean) => void;
  onChangeZeck: (zeck: ZeckLite, optimistic?: boolean) => Promise<void>;
  onMoveZeck: (zeckId: string, folderId: string | null) => Promise<void>;
  EmptyZeckNode: React.ReactNode;
  pageTitle: string;
  isHome: boolean;
}> = ({
  zecks,
  folders,
  onClickDeleteZeck,
  onClickDuplicateZeck,
  onChangeZeck,
  onMoveZeck,
  EmptyZeckNode,
  pageTitle,
  isHome,
}) => {
  useDocumentTitle(pageTitle);

  if (isHome && folders.type !== 'success') {
    return <LoaderSpinnerOverlay />;
  }

  const showEmptyList = isHome
    ? zecks.length === 0 &&
      folders.type === 'success' &&
      folders.data.length === 0
    : zecks.length === 0;

  return (
    <ZeckListWrapper>
      <ZeckListTitle title={pageTitle} />

      {showEmptyList ? (
        EmptyZeckNode
      ) : isHome ? (
        <ZeckHomeWithFolderList
          {...{
            zecks,
            folders,
            onMoveZeck,
            onClickDeleteZeck,
            onClickDuplicateZeck,
            onChangeZeck,
          }}
        />
      ) : (
        <ZeckListBody
          {...{
            zecks,
            folders,
            onMoveZeck,
            onClickDeleteZeck,
            onClickDuplicateZeck,
            onChangeZeck,
          }}
        />
      )}
    </ZeckListWrapper>
  );
};

export default ZecksHomepageContent;

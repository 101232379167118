import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useApi from '../../api/useApi.js';
import useToast from '../../design-system/molecules/toast/useToast.js';
import {
  createDefaultZeckBody,
  createDefaultZeckHeadline,
  createDefaultZeckSectionTitle,
} from '../../junkDrawer/DefaultContent.js';
import noop from '../../junkDrawer/noop.js';
import {
  chainResult,
  foldResult,
  isSuccessResult,
  mapResult,
  pipe,
} from '../../result/Result.js';
import {
  companyHomePath,
  zeckEditPath,
  zeckFolderPath,
} from '../../services/Paths.js';
import { Result } from '../../services/useFetch/useFetch.js';
import useFetchInvalidate from '../../services/useFetch/useFetchInvalidate.js';
import usePageTracking, {
  PagesToTrack,
} from '../../services/usePageTracking.ts';
import { CompanyLite } from '../../types/CompanyLite.js';
import { ZeckFolderWithCount } from '../../types/ZeckFolder.js';
import { ZeckLite } from '../../types/ZeckLite.js';
import { UserAndCompany } from '../../userAndCompany/FetchUserAndCompany.js';
import useHomepageFolders from '../useHomepageFolders.js';
import AdminHomepage from './AdminHomepage.js';
import CreateZeckModal from './CreateZeckModal.js';
import ZeckListEmpty from './ZeckListEmpty.tsx';
import ZecksHomepageContent from './ZecksHomepageContent.js';
import ZecksTrashedHomepageContent from './ZecksTrashedHomepageContent.js';
import { useZeckTrashActions } from './useZeckTrashActions.js';

function useZeckActions(
  zecksResult: Result<ZeckLite[]>,
  invalidateZecks: (
    action: () => Promise<void>,
    optimisticData?: ZeckLite[],
  ) => Promise<void>,
  userAndCompany: UserAndCompany,
) {
  const { trashZeck, updateZeck, duplicateZeck } = useApi();

  return pipe(
    zecksResult,
    foldResult({
      loading: () => ({
        onClickDeleteZeck: noop,
        onClickDuplicateZeck: noop,
        onChangeZeck: async () => {},
      }),
      error: () => ({
        onClickDeleteZeck: noop,
        onClickDuplicateZeck: noop,
        onChangeZeck: async () => {},
      }),
      success: (zecks) => ({
        onClickDeleteZeck: (id: string) => {
          invalidateZecks(() => trashZeck(userAndCompany.activeCompany.id, id));
        },
        onClickDuplicateZeck: (
          zeckId: string,
          duplicatePermissions: boolean,
        ) => {
          const zeck = zecks.find(({ id }) => id === zeckId);

          if (!zeck) return;
          invalidateZecks(() =>
            duplicateZeck(
              userAndCompany.activeCompany,
              zeckId,
              duplicatePermissions,
            ),
          );
        },
        onChangeZeck: (newZeck: ZeckLite, optimistic = true) =>
          invalidateZecks(
            () =>
              updateZeck(newZeck.id, {
                name: newZeck.name,
                folderId: newZeck.folderId,
              }),
            optimistic
              ? zecks.map((zeck) => (zeck.id === newZeck.id ? newZeck : zeck))
              : undefined,
          ),
      }),
    }),
  );
}

const useCreateZeckWithDefaultContent = (
  company: CompanyLite,
  folderId: string | undefined,
) => {
  const { createZeck, createSection } = useApi();

  return async ({ zeckName }: { zeckName: string }) => {
    const newZeck = await createZeck({
      company: company,
      name: zeckName,
      folderId: folderId,
    });
    await createSection({
      zeckId: newZeck.id,
      title: createDefaultZeckSectionTitle(),
      headline: createDefaultZeckHeadline(),
      body: createDefaultZeckBody(),
      supplemental: false,
    });

    return newZeck;
  };
};

const useMoveZeckToFolder = (
  zecksResult: Result<ZeckLite[]>,
  foldersResult: Result<ZeckFolderWithCount[]>,
  invalidateZecks: (
    action: () => Promise<void>,
    optimisticData?: ZeckLite[],
  ) => Promise<void>,
  userAndCompany: UserAndCompany,
  showToast: (message: string) => void,
) => {
  const { updateZeck } = useApi();

  return pipe(
    zecksResult,
    chainResult((zecks) =>
      pipe(
        foldersResult,
        mapResult((folders) => ({
          zecks,
          folders,
        })),
      ),
    ),
    foldResult({
      success:
        ({ zecks, folders }) =>
        async (zeckId: string, folderId: string | null) => {
          const zeck = zecks.find((zeck) => zeck.id === zeckId);
          const folder = [...folders, { id: null, name: 'Home' }].find(
            (folder) => folder.id === folderId,
          );
          if (!zeck || !folder || zeck.folderId === folderId) return;
          return invalidateZecks(
            async () => {
              await updateZeck(zeckId, {
                folderId: folderId,
              });

              showToast(`Moved to ${folder.name}`);
            },
            zecks.filter((zeck) => zeck.id !== zeckId),
          );
        },
      loading: () => async () => {},
      error: () => async () => {},
    }),
  );
};

type AdminHomepageSource = 'zeck-trash' | 'folder' | 'home';
const pageNameMap: Record<AdminHomepageSource, PagesToTrack> = {
  'zeck-trash': 'zeck_trash',
  folder: 'zeck_folder',
  home: 'home',
};

const AdminHomepageContainer: React.FC<{
  userAndCompany: UserAndCompany;
  onClickLogout: () => void;
  source: AdminHomepageSource;
}> = (props) => {
  usePageTracking(pageNameMap[props.source], props.userAndCompany);
  const navigate = useNavigate();
  const { folderId } = useParams();
  const activeCompanyId = props.userAndCompany.activeCompany.id;

  const { listZecks, listTrashedZecks } = useApi();

  const createZeckWithDefaultContent = useCreateZeckWithDefaultContent(
    props.userAndCompany.activeCompany,
    folderId,
  );

  const {
    foldersResult,
    updateFolder,
    createFolder,
    deleteZeckFolder,
    invalidateFolders,
  } = useHomepageFolders(activeCompanyId, (folder) =>
    navigate(zeckFolderPath(folder)),
  );

  const { showToast, ...toast } = useToast();

  const onDeleteFolder = async function (folderId: string) {
    await deleteZeckFolder(folderId);

    if (isSuccessResult(foldersResult)) {
      const deletedFolder = foldersResult.data.find(
        (folder) => folder.id === folderId,
      );
      showToast(`${deletedFolder?.name} Has Been Deleted`);
    } else {
      showToast(`Folder Has Been Deleted`);
    }

    if (folderId === folderId) {
      navigate(companyHomePath(activeCompanyId), {
        replace: true,
      });
    }
  };

  const [isCreatingZeck, setIsCreatingZeck] = useState(false);

  const { result: zecksResult, invalidate: invalidateZecksRaw } =
    useFetchInvalidate(() => {
      if (props.source === 'zeck-trash') {
        return listTrashedZecks(props.userAndCompany.activeCompany);
      }
      return listZecks(props.userAndCompany.activeCompany, folderId);
    }, [folderId, props.source]);

  // changing zecks can change zeck folder zeckCounts
  const invalidateZecks = async (
    action: () => Promise<void>,
    optimisticData?: ZeckLite[],
  ) => {
    await invalidateFolders(async () => {
      await invalidateZecksRaw(action, optimisticData);
    });
  };

  const zeckActions = useZeckActions(
    zecksResult,
    invalidateZecks,
    props.userAndCompany,
  );

  const zeckTrashActions = useZeckTrashActions(
    zecksResult,
    foldersResult,
    invalidateZecks,
    props.userAndCompany.activeCompany,
    showToast,
  );

  const onMoveZeck = useMoveZeckToFolder(
    zecksResult,
    foldersResult,
    invalidateZecks,
    props.userAndCompany,
    showToast,
  );
  const onClickCreateZeck = () => {
    setIsCreatingZeck(true);
  };

  const getProps = () => {
    switch (props.source) {
      case 'zeck-trash': {
        return {
          activePage: 'zeck-trash',
          pageTitle: 'Deleted Zecks',
          onClickCreateZeck: undefined,
          onUpdateFolder: undefined,
          onCreateFolder: undefined,
          onDeleteFolder: undefined,
          renderZecks: (zecks: ZeckLite[]) => {
            return (
              <ZecksTrashedHomepageContent
                zecks={zecks}
                restoreZeck={zeckTrashActions.restoreZeck}
                destroyZeck={zeckTrashActions.destroyZeck}
              />
            );
          },
        };
      }
      case 'home': {
        return {
          activePage: 'home',
          pageTitle: 'Home',
          onClickCreateZeck,
          onUpdateFolder: updateFolder,
          onCreateFolder: createFolder,
          onDeleteFolder,
          renderZecks: (zecks: ZeckLite[]) => {
            return (
              <ZecksHomepageContent
                zecks={zecks}
                folders={foldersResult}
                onClickDeleteZeck={zeckActions.onClickDeleteZeck}
                onClickDuplicateZeck={zeckActions.onClickDuplicateZeck}
                onChangeZeck={zeckActions.onChangeZeck}
                onMoveZeck={onMoveZeck}
                pageTitle="Home"
                isHome={true}
                EmptyZeckNode={
                  <ZeckListEmpty onClickCreateZeck={onClickCreateZeck} />
                }
              />
            );
          },
        };
      }
      case 'folder': {
        const pageTitle = pipe(
          foldersResult,
          foldResult({
            success: (folders) =>
              folders.find((folder) => folder.id === folderId)?.name || '',
            loading: () => '',
            error: () => '',
          }),
        );

        return {
          activePage: folderId || '',
          pageTitle,
          onClickCreateZeck,
          onUpdateFolder: updateFolder,
          onCreateFolder: createFolder,
          onDeleteFolder,
          renderZecks: (zecks: ZeckLite[]) => {
            return (
              <ZecksHomepageContent
                zecks={zecks}
                folders={foldersResult}
                onClickDeleteZeck={zeckActions.onClickDeleteZeck}
                onClickDuplicateZeck={zeckActions.onClickDuplicateZeck}
                onChangeZeck={zeckActions.onChangeZeck}
                onMoveZeck={onMoveZeck}
                pageTitle={pageTitle}
                isHome={false}
                EmptyZeckNode={
                  <ZeckListEmpty onClickCreateZeck={onClickCreateZeck} />
                }
              />
            );
          },
        };
      }
    }
  };

  return (
    <>
      <AdminHomepage
        {...{
          onClickLogout: props.onClickLogout,
          userAndCompany: props.userAndCompany,
          toast,
          zecksResult,
          foldersResult,
          onMoveZeck,
          ...getProps(),
        }}
      />
      <CreateZeckModal
        {...{
          isOpen: isCreatingZeck,
          onSubmit: async ({ zeckName }: { zeckName: string }) => {
            const newZeck = await createZeckWithDefaultContent({ zeckName });
            navigate(zeckEditPath(newZeck));
          },
          onClose: () => {
            setIsCreatingZeck(false);
          },
        }}
      />
    </>
  );
};

export default AdminHomepageContainer;

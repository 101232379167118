import { initContract } from '@ts-rest/core';
import { z } from 'zod';
import { version } from './version.ts';

const c = initContract();

const ImageBlockSchema = z.object({
  type: z.literal('image'),
  id: z.string().uuid(),
  guid: z.string().uuid(),
  caption: z.string(),
  width: z.enum(['column', 'wide', 'full-width']),
  align: z.enum(['left', 'center']),
  dimensions: z.optional(
    z.object({
      width: z.number(),
      height: z.number(),
    }),
  ),
});

const TextFormatSchema = z.object({
  bold: z.boolean().optional(),
  italic: z.boolean().optional(),
  underline: z.boolean().optional(),
});

const NonHighlightTextNodeSchema = z.union([
  z.object({
    type: z.literal('text'),
    text: z.string(),
    format: TextFormatSchema.optional(),
  }),
  z.object({
    type: z.literal('external-link'),
    content: z.array(
      z.object({
        type: z.literal('text'),
        text: z.string(),
        format: TextFormatSchema.optional(),
      }),
    ),
    url: z.string().url(),
  }),
  z.object({
    type: z.literal('section-link'),
    content: z.array(
      z.object({
        type: z.literal('text'),
        text: z.string(),
        format: TextFormatSchema.optional(),
      }),
    ),
    sectionId: z.string().uuid(),
  }),
  z.object({
    type: z.literal('meeting-minutes-link'),
    meetingMinutesId: z.string().uuid(),
    content: z.array(
      z.object({
        type: z.literal('text'),
        text: z.string(),
        format: TextFormatSchema.optional(),
      }),
    ),
  }),
]);

const HighlightSchema = z.object({
  type: z.literal('highlight'),
  ids: z.array(z.string().uuid()),
  content: z.array(NonHighlightTextNodeSchema),
});

const TextNodeSchema = z.union([NonHighlightTextNodeSchema, HighlightSchema]);

const TextBlockContentSchema = z.array(TextNodeSchema);

const ParagraphBlockSchema = z.object({
  type: z.literal('paragraph'),
  id: z.string().uuid(),
  content: TextBlockContentSchema,
});

const HeadingBlockSchema = z.object({
  type: z.literal('heading'),
  id: z.string().uuid(),
  content: TextBlockContentSchema,
});

const LabelBlockSchema = z.object({
  type: z.literal('label'),
  id: z.string().uuid(),
  content: TextBlockContentSchema,
});

const BulletedListItemBlockSchema = z.object({
  type: z.literal('bulleted-list-item'),
  id: z.string().uuid(),
  content: TextBlockContentSchema,
  indent: z.number(),
});

const NumberedListItemBlockSchema = z.object({
  type: z.literal('numbered-list-item'),
  id: z.string().uuid(),
  content: TextBlockContentSchema,
  indent: z.number(),
});

const TextBlockSchema = z.union([
  ParagraphBlockSchema,
  HeadingBlockSchema,
  LabelBlockSchema,
  BulletedListItemBlockSchema,
  NumberedListItemBlockSchema,
]);

const CommentContentSchema = z.array(
  z.discriminatedUnion('type', [
    z.object({
      type: z.literal('text'),
      text: z.string(),
    }),
    z.object({
      type: z.literal('tag'),
      userId: z.string().uuid(),
      displayName: z.string(),
    }),
  ]),
);
const CommentReplyContentSchema = z.array(
  z.discriminatedUnion('type', [
    z.object({
      type: z.literal('text'),
      text: z.string(),
    }),
    z.object({
      type: z.literal('tag'),
      userId: z.string().uuid(),
      displayName: z.string(),
    }),
  ]),
);

export const PublishedCommentReplySchema = z.object({
  id: z.string().uuid(),
  createdAt: z.number(),
  updatedAt: z.number(),

  parentId: z.string().uuid(),
  userId: z.string().uuid(),

  userName: z.string(),
  content: CommentReplyContentSchema,
  type: z.enum(['CommentReply', 'DirectMessageReply']),
});

// this is a content selection for now, but naming it
// abstractly since it's conceivable a selected table
// cell range has a different format
export const SnippetSelectionSchema = z.object({
  anchorOffset: z.number(),
  focusOffset: z.number(),
});

const PublishedCommentSelectionSchema = z.object({
  block: z.union([ImageBlockSchema, TextBlockSchema]),
  snippetSelection: z.union([SnippetSelectionSchema, z.null()]),
});

export type PublishedCommentSelection = z.infer<
  typeof PublishedCommentSelectionSchema
>;

export const PublishedCommentSchema = z.object({
  id: z.string().uuid(),
  createdAt: z.number(),
  updatedAt: z.number(),

  sectionId: z.string().uuid(),
  userId: z.string().uuid(),

  userName: z.string(),
  content: CommentContentSchema,
  replies: z.array(PublishedCommentReplySchema),
  viewers: z.array(z.string().uuid()),
  type: z.enum(['Comment', 'DirectMessage']),
  resolved: z.boolean(),
  starred: z.boolean(),
  selection: z.union([PublishedCommentSelectionSchema, z.null()]),
});

const AuthHeadersSchema = z.object({
  authorization: z.string().startsWith('Bearer '),
});

const PublishedCommentTagsSchema = z.object({
  resolved: z.boolean(),
  starred: z.boolean(),
});

export const publishedCommentContract = c.router({
  create: {
    summary: 'Create a published comment',
    method: 'POST',
    path: '/section/:sectionId/published-comment',
    pathParams: z.object({ sectionId: z.string().uuid() }),
    responses: {
      201: PublishedCommentSchema,
      401: c.type(),
      403: c.type(),
      404: c.type(),
      426: c.type(),
      500: c.type(),
    },
    body: z.object({
      content: CommentContentSchema,
      selection: z.optional(PublishedCommentSelectionSchema),
      directMessage: z.boolean(),
    }),
    headers: AuthHeadersSchema,
    strictStatusCodes: true,
  },
  update: {
    summary: 'Update a published comment',
    method: 'PATCH',
    path: '/published-comment/:publishedCommentId',
    responses: {
      200: PublishedCommentSchema,
    },
    pathParams: z.object({ publishedCommentId: z.string().uuid() }),
    headers: AuthHeadersSchema,
    body: z.object({
      content: CommentContentSchema,
    }),
  },
  tagsUpdate: {
    summary: "Update a published comment's tags",
    method: 'PATCH',
    path: '/published-comment/:publishedCommentId/tags',
    responses: {
      200: PublishedCommentTagsSchema,
      401: c.type(),
      403: c.type(),
      404: c.type(),
      500: c.type(),
    },
    pathParams: z.object({ publishedCommentId: z.string().uuid() }),
    headers: AuthHeadersSchema,
    body: PublishedCommentTagsSchema.partial(),
    strictStatusCodes: true,
  },
  getAll: {
    summary: 'Get all published comments for a zeck',
    method: 'GET',
    path: '/zeck/:zeckId/published-comment',
    pathParams: z.object({ zeckId: z.string().uuid() }),
    responses: {
      200: z.array(PublishedCommentSchema),
    },
    headers: AuthHeadersSchema,
  },
  delete: {
    summary: 'Delete a published comment',
    method: 'DELETE',
    path: '/published-comment/:publishedCommentId',
    pathParams: z.object({ publishedCommentId: z.string().uuid() }),
    responses: {
      200: z.null(),
    },
    headers: AuthHeadersSchema,
  },
  replyCreate: {
    summary: 'Create a published comment reply',
    method: 'POST',
    path: '/published-comment/:publishedCommentId/reply',
    pathParams: z.object({ publishedCommentId: z.string().uuid() }),
    responses: {
      201: PublishedCommentReplySchema,
    },
    body: z.object({
      content: CommentReplyContentSchema,
    }),
    headers: AuthHeadersSchema,
  },
  replyUpdate: {
    summary: 'Update a published comment reply',
    method: 'PATCH',
    path: '/published-comment-reply/:publishedCommentReplyId',
    responses: {
      200: PublishedCommentReplySchema,
    },
    pathParams: z.object({ publishedCommentReplyId: z.string().uuid() }),
    headers: AuthHeadersSchema,
    body: z.object({
      content: CommentContentSchema,
    }),
  },
  replyDelete: {
    summary: 'Delete a published comment reply',
    method: 'DELETE',
    path: '/published-comment-reply/:publishedCommentReplyId',
    pathParams: z.object({ publishedCommentReplyId: z.string().uuid() }),
    responses: {
      200: z.null(),
    },
    headers: AuthHeadersSchema,
  },
});
